import axios from "axios";
import qs from "qs";
import { Dispatch } from "redux";

import environment from "../../environment";
import { errorHandler } from "../../utils/errorHandler";
import { getToken } from "../../utils/cookies/tokensCookie";
import {
  ADD_CORPORATE,
  CLEAR_CORPORATES_MESSAGES,
  CORPORATES_ERROR,
  CORPORATES_LOADING,
  corporatesDispatchTypes,
  DELETE_CORPORATE,
  GET_CORPORATE,
  GET_CORPORATES_INFORMATION,
  UPDATE_CORPORATE,
} from "../types/corporatesTypes";
import {urls} from "../../API/urls";

// Get company data
export const getCorporates =
  (limit: number, skip: number, search?: string) =>
    async (dispatch: Dispatch<corporatesDispatchTypes>) => {
      const token = getToken();
      const fullUrl = `${environment.baseUrl}${urls.corporates.corporates}`;
      const dynamicParams = {
        limit: limit,
        skip: skip,
      };
      if (search) {
        Object.assign(dynamicParams, { search: search });
      }
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        params: dynamicParams,
        paramsSerializer: (params: any) => qs.stringify(params),
      };
      let company: any = {};
      try {
        const request = await axios.get(fullUrl, options);
        company = request.data;
        dispatch({
          type: GET_CORPORATES_INFORMATION,
          payload: company,
        });
      } catch (e: any) {
        if (!errorHandler(e.request.status)) {
          dispatch({
            type: CORPORATES_ERROR,
            payload: "get_corporates",
          });
        }
      }
    };

// Get corporate data
export const getCorporate =
  (id: string) => async (dispatch: Dispatch<corporatesDispatchTypes>) => {
    const token = getToken();
    const fullUrl = `${environment.baseUrl}${urls.corporates.corporates}/${id}`;
    const options = {
      headers: { Authorization: `Bearer ${token}` },
      paramsSerializer: (params: any) => qs.stringify(params),
    };
    try {
      const request = await axios.get(fullUrl, options);

      dispatch({
        type: GET_CORPORATE,
        payload: request.data,
      });
    } catch (e: any) {
      if (!errorHandler(e.request.status)) {
        dispatch({
          type: CORPORATES_ERROR,
          payload: "get_corporate",
        });
      }
    }
  };

// Post new Corporate
export const postCorporate =
  (data: any) => async (dispatch: Dispatch<corporatesDispatchTypes>) => {
    const token = getToken();
    const fullUrl = `${environment.baseUrl}${urls.corporates.corporates}`;
    const options = { headers: { Authorization: `Bearer ${token}` } };
    try {
      await axios.post(fullUrl, data, options);
      dispatch({
        type: ADD_CORPORATE,
        payload: "post_corporate",
      });
    } catch (e: any) {
      if (!errorHandler(e.request.status)) {
        dispatch({
          type: CORPORATES_ERROR,
          payload: "post_corporate",
        });
      }
    }
  };

// Update Corporate
export const updateCorporate =
  (data: any, id: string) =>
    async (dispatch: Dispatch<corporatesDispatchTypes>) => {
      const token = getToken();
      const fullUrl = `${environment.baseUrl}${urls.corporates.corporates}/${id}`;
      const options = { headers: { Authorization: `Bearer ${token}` } };
      try {
        await axios.put(fullUrl, data, options);
        dispatch({
          type: UPDATE_CORPORATE,
          payload: "update_corporate",
        });
      } catch (e: any) {
        if (!errorHandler(e.request.status)) {
          dispatch({
            type: CORPORATES_ERROR,
            payload: "update_corporate",
          });
        }
      }
    };

// Delete Corporate
export const deleteCorporate =
  (id: string) => async (dispatch: Dispatch<corporatesDispatchTypes>) => {
    const token = getToken();
    const fullUrl = `${environment.baseUrl}${urls.corporates.corporates}/${id}`;
    const options = { headers: { Authorization: `Bearer ${token}` } };
    try {
      await axios.delete(fullUrl, options);
      dispatch({
        type: DELETE_CORPORATE,
        payload: "delete_corporate",
      });
    } catch (e: any) {
      if (!errorHandler(e.request.status)) {
        dispatch({
          type: CORPORATES_ERROR,
          payload: "delete_corporate",
        });
      }
    }
  };

// Show loading
export function showCorporatesLoading() {
  return {
    type: CORPORATES_LOADING,
  };
}

// Clear messages
export function clearCorporatesMessages() {
  return {
    type: CLEAR_CORPORATES_MESSAGES,
  };
}
