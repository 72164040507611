import "../SimpleComponents/Modal.css";

import React from "react";
import { useTranslation } from "react-i18next";
import {PropagateLoader} from "react-spinners";


export default function DownloadFilesModal() {
  const { t } = useTranslation();

  return (
    <div className="modal_container">
      <div className="file_loader">
        <PropagateLoader size="10px" color="#0e0a40" />
      </div>
      <p className="file_loading_text">
        {t("dashboard.checklists.modal.text")}
      </p>
    </div>
  );
}
