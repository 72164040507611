import "./styles.css";

import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import { toast } from "react-toastify";
import * as Yup from "yup";

import {
  clearCorporatesMessages,
  getCorporate,
  postCorporate,
  updateCorporate,
} from "../../redux/actions/corporatesActions";
import { RootState } from "../../redux/reducers/rootReducer";
import Button from "../Buttons/Button";
import ContentContainer from "../Containers/ContentContainer";
import MainSpace from "../Containers/MainSpace";
import FormikInput from "../SimpleComponents/FormikInput";
import ImageUpload from "../SimpleComponents/ImageUpload";
import CustomToastContainer from "../SimpleComponents/ToastContainer";
import {useNavigate} from "react-router-dom";

interface CustomProps {
  corporateId?: string;
  backToPage?: () => void;
  corporatesState?: any;
  corporateData?: any;
}

function CorporatesDetail({
  corporateId,
  backToPage,
  corporatesState,
  corporateData,
}: CustomProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formik = useFormik<any>({
    initialValues: {
      name: "",
      domain: "",
      notiz: "",
      launch: "",
      eingereicht: "",
      deadline: "",
      paket: "",
      sieDu: "",
      interneAngebote: "",
      startguthaben: "",
      secureCode: "",
      secureCodeCheck: "",
      blacklist: "",
      mailRestrictions: "",
      imageKey: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(
        t("dashboard.benefits.validation.name_required")
      ),
      domain: Yup.string().required(
        t("dashboard.benefits.validation.domain_required")
      ),
    }),
    // handle form submitting
    onSubmit: () => console.log("CLICK"),
  });

  const backClick = () => {
    if (corporateId) {
      navigate("/benefits");
    } else if (!corporateId && backToPage) {
      backToPage();
    }
  };

  // Show success/error
  useEffect(() => {
    if (corporatesState.success === "post_corporate") {
      dispatch(clearCorporatesMessages());
      toast.success(
        <CustomToastContainer
          message={t("dashboard.benefits.toast.success_add")}
          status={1}
        />,
        {
          autoClose: 2000,
        }
      );
      backClick();
    } else if (corporatesState.error === "post_corporate") {
      dispatch(clearCorporatesMessages());
      toast.error(
        <CustomToastContainer
          message={t("dashboard.benefits.toast.error_add")}
          status={3}
        />,
        {
          autoClose: 2000,
        }
      );
    } else if (corporatesState.success === "update_corporate") {
      dispatch(clearCorporatesMessages());
      backClick();
      toast.success(
        <CustomToastContainer
          message={t("dashboard.benefits.toast.success_update")}
          status={1}
        />,
        {
          autoClose: 2000,
        }
      );
    } else if (corporatesState.error === "update_corporate") {
      dispatch(clearCorporatesMessages());
      toast.error(
        <CustomToastContainer
          message={t("dashboard.benefits.toast.error_update")}
          status={3}
        />,
        {
          autoClose: 2000,
        }
      );
    } else if (corporatesState.success === "delete_corporate") {
      dispatch(clearCorporatesMessages());
      toast.success(
        <CustomToastContainer
          message={t("dashboard.benefits.toast.success_delete")}
          status={1}
        />,
        {
          autoClose: 2000,
        }
      );
    } else if (corporatesState.error === "delete_corporate") {
      dispatch(clearCorporatesMessages());
      toast.success(
        <CustomToastContainer
          message={t("dashboard.benefits.toast.error_delete")}
          status={1}
        />,
        {
          autoClose: 2000,
        }
      );
    }
  }, [corporatesState]);

  // Form submitting
  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    if (corporateId) {
      dispatch(updateCorporate(formik.values, corporateId));
    } else {
      dispatch(postCorporate(formik.values));
    }
  };

  //get Corporates data
  useEffect(() => {
    if (corporateId) {
      dispatch(getCorporate(corporateId));
    }
  }, []);

  const [companyName, setCompanyName] = useState("");

  useEffect(() => {
    if (Object.keys(corporateData).length) {
      setCompanyName(corporateData.name);
      formik.setFieldValue("name", corporateData.name, false);
      formik.setFieldValue("domain", corporateData.domain, false);
      formik.setFieldValue("notiz", corporateData.notiz, false);
      formik.setFieldValue("launch", corporateData.launch, false);
      formik.setFieldValue("eingereicht", corporateData.eingereicht, false);
      formik.setFieldValue("deadline", corporateData.deadline, false);
      formik.setFieldValue("paket", corporateData.paket, false);
      formik.setFieldValue("sieDu", corporateData.sieDu, false);
      formik.setFieldValue("interneAngebote",corporateData.interneAngebote,false);
      formik.setFieldValue("startguthaben", corporateData.startguthaben, false);
      formik.setFieldValue("secureCode", corporateData.secureCode, false);
      formik.setFieldValue(
        "secureCodeCheck",
        corporateData.secureCodeCheck,
        false
      );
      formik.setFieldValue("blacklist", corporateData.blacklist, false);
      formik.setFieldValue(
        "mailRestrictions",
        corporateData.mailRestrictions,
        false
      );
      formik.setFieldValue("imageKey", corporateData.imageKey, false);
    }
  }, [corporateData]);

  // Disable button till request will be not finished
  const [loadingButton, setLoadingButton] = useState(false);

  return (
    <ContentContainer>
      <MainSpace>
        <form onSubmit={(e) => handleFormSubmit(e)}>
          <div className="row" style={{ marginBottom: "10px" }}>
            <p onClick={backClick} className="crumb">
              {t("dashboard.benefits.bread_prev")}
            </p>
            <p className="crumb_arrow">{">"}</p>
            <p className="crumb_current">
              {corporateId
                ? companyName
                : t("dashboard.benefits.brad_new_corporate")}
            </p>
          </div>
          <div className="corporate_grid">
            <div>
              <div className="corporate_input_grid">
                <div className="relative">
                  <FormikInput
                    htmlFor="name"
                    name="name"
                    value={formik.values.name}
                    disabled={false}
                    handleChange={formik.handleChange}
                    label={t("dashboard.benefits.input_labels.name")}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.name && formik.touched.name && (
                    <p className="input_warning">
                      {formik?.errors?.corporateName?.toString()}
                    </p>
                  )}
                </div>
                <div className="relative">
                  <FormikInput
                    htmlFor="domain"
                    name="domain"
                    value={formik.values.domain}
                    disabled={false}
                    handleChange={formik.handleChange}
                    label={t("dashboard.benefits.input_labels.domain")}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.domain && formik.touched.domain && (
                    <p className="input_warning">{formik.errors.domain.toString()}</p>
                  )}
                </div>
              </div>
              <div className="simple_input">
                <label className="simple_label" htmlFor="notiz">
                  {t("dashboard.benefits.input_labels.notes")}
                </label>
                <textarea
                  onChange={formik.handleChange}
                  value={formik.values.notiz}
                  name="notiz"
                  rows={5}
                  className="textarea"
                  style={{ resize: "none" }}
                />
              </div>
              <div className="corporate_grid_3">
                <FormikInput
                  htmlFor="launch"
                  name="launch"
                  value={formik.values.launch}
                  disabled={false}
                  handleChange={formik.handleChange}
                  label={t("dashboard.benefits.input_labels.launch")}
                />
                <FormikInput
                  htmlFor="eingereicht"
                  name="eingereicht"
                  value={formik.values.eingereicht}
                  disabled={false}
                  handleChange={formik.handleChange}
                  label={t("dashboard.benefits.input_labels.submitted")}
                />
                <FormikInput
                  htmlFor="deadline"
                  name="deadline"
                  value={formik.values.deadline}
                  disabled={false}
                  handleChange={formik.handleChange}
                  label={t("dashboard.benefits.input_labels.deadline")}
                />
              </div>
              <div className="corporate_grid_3">
                <FormikInput
                  htmlFor="paket"
                  name="paket"
                  value={formik.values.paket}
                  disabled={false}
                  handleChange={formik.handleChange}
                  label={t("dashboard.benefits.input_labels.plan")}
                />
                <FormikInput
                  htmlFor="sieDu"
                  name="sieDu"
                  value={formik.values.sieDu}
                  disabled={false}
                  handleChange={formik.handleChange}
                  label={t("dashboard.benefits.input_labels.handling")}
                />
                <FormikInput
                  htmlFor="interneAngebote"
                  name="interneAngebote"
                  value={formik.values.interneAngebote}
                  disabled={false}
                  handleChange={formik.handleChange}
                  label={t("dashboard.benefits.input_labels.internOffers")}
                />
              </div>
              <div className="corporate_grid_3">
                <FormikInput
                  htmlFor="startguthaben"
                  name="startguthaben"
                  value={formik.values.startguthaben}
                  disabled={false}
                  handleChange={formik.handleChange}
                  label={t("dashboard.benefits.input_labels.credit")}
                />
                <FormikInput
                  htmlFor="secureCode"
                  name="secureCode"
                  value={formik.values.secureCode}
                  disabled={false}
                  handleChange={formik.handleChange}
                  label={t("dashboard.benefits.input_labels.secureCode")}
                />
                <FormikInput
                  htmlFor="secureCodeCheck"
                  name="secureCodeCheck"
                  value={formik.values.secureCodeCheck}
                  disabled={false}
                  handleChange={formik.handleChange}
                  label={t("dashboard.benefits.input_labels.secureCheck")}
                />
              </div>
              <FormikInput
                htmlFor="blacklist"
                name="blacklist"
                value={formik.values.blacklist}
                disabled={false}
                handleChange={formik.handleChange}
                label={t("dashboard.benefits.input_labels.blackList")}
              />
              <FormikInput
                htmlFor="mailRestrictions"
                name="mailRestrictions"
                value={formik.values.mailRestrictions}
                disabled={false}
                handleChange={formik.handleChange}
                label={t("dashboard.benefits.input_labels.mailRestrictions")}
              />
            </div>
            <ImageUpload
              ImageUrl={formik.values.imageKey}
              setImageUrl={(url: any) => console.log(url)}
              update={(e, val) => formik.setFieldValue("imageKey", val)}
            />
            {loadingButton ? (
              <div className="center primary btn_full">
                <BeatLoader size="10px" color="white" />
              </div>
            ) : (
              <Button
                submitButton={true}
                buttonType="primary"
                buttonLabel={
                  corporateId
                    ? t("dashboard.benefits.btn_update")
                    : t("dashboard.benefits.btn_create")
                }
                disabled={!(formik.isValid && formik.dirty)}
              />
            )}
          </div>
        </form>
      </MainSpace>
    </ContentContainer>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    corporatesState: state.corporates,
    corporateData: state.corporates.corporate,
  };
};

export default connect(mapStateToProps, null)(CorporatesDetail);
