import "./ContentContainer.css";

import React from "react";

const ContentContainer = (props: any) => {
  return (
    <div
      className={props.withoutHeader ? "main_container_full" : "main-container"}
    >
      {props.children}
    </div>
  );
};

export default ContentContainer;
