import ContentContainer from "Components/Containers/ContentContainer";
import MainSpace from "Components/Containers/MainSpace";
import companiesEmptyIcon from "img/Dashboard/companies_empty_state.svg";
import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";

import {
  clearChecklistsMessages,
  getChecklists,
  showChecklistsLoading,
} from "../../redux/actions/checkListsActions";
import { RootState } from "../../redux/reducers/rootReducer";
import ChecklistsList from "../Lists/ChecklistsList";
import SearchBarLight from "../SimpleComponents/SearchBarLight";
import Modal from "react-modal";
import DownloadFilesModal from "../Modals/DownloadFilesLoading";
import {toast} from "react-toastify";
import CustomToastContainer from "../SimpleComponents/ToastContainer";
import ChangelogModal from "../Modals/ChangeLogModal";

// Styles for modal window
const customStyles = {
  overlay: {
    backgroundColor: "rgba(55,52,52,0.7)",
    zIndex: 9999,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
if (process.env.NODE_ENV !== "test") Modal.setAppElement("#root");


interface CustomProps {
  listsState?: any;
  checklistsFilesLoading?: boolean;
}

function ChecklistsPage({ listsState, checklistsFilesLoading }: CustomProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getChecklists(8, 0));
  }, [dispatch]);

  // handle search bar values
  const handleSearch = (val: string) => {
    dispatch(showChecklistsLoading());
    dispatch(getChecklists(8, 0, val));
  };

  // Show/Hide loading modal for files download
  const [showModal, setShowModal] = useState(false);

  // Hide Modal after file was downloaded
  useEffect(() => {
    if (checklistsFilesLoading) {
      setShowModal(true);
    } else {
      setShowModal(false);
      dispatch(clearChecklistsMessages());
    }
  }, [checklistsFilesLoading]);

  // Show error message if no files
  useEffect(() => {
    if (listsState.message === "download_files") {
      toast.error(
        <CustomToastContainer
          message={t("warnings.checklist_files")}
          status={3}
        />,
        {
          autoClose: 2000,
        }
      );
    }
  }, [listsState]);

  // Show hide changelog modal
  const [showChangelogModal, setShowChangelogModal] = useState(false);
  const [changeLogData, setChangeLogData] = useState([]);
  const showChangeLog = (data:any) => {
    setChangeLogData(data);
    setShowChangelogModal(true);
  };

  return (
    <ContentContainer>
      <MainSpace>
        <Modal style={customStyles} isOpen={showModal}>
          <DownloadFilesModal />
        </Modal>
        <Modal style={customStyles} isOpen={showChangelogModal}>
          <ChangelogModal
            closeModal={() => setShowChangelogModal(false)}
            changeLogData={changeLogData}
          />
        </Modal>
        <div className="flex-end">
          <SearchBarLight
            handleSearchBar={handleSearch}
            searchPlaceholder={t("dashboard.overview.search")}
          />
        </div>
        {listsState.lists.data && listsState.lists.data.length > 0 ? (
          <>
            <ChecklistsList
              checklists={listsState.lists}
              showChangeLog={showChangeLog}
            />
          </>
        ) : (
          <div className="companies_table_container">
            <img src={companiesEmptyIcon} alt="emptyState" />
            <h3>{t("dashboard.companies.empty_title")}</h3>
            <p>{t("dashboard.companies.empty_text")}</p>
          </div>
        )}
      </MainSpace>
    </ContentContainer>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    listsState: state.checklists,
    checklistsFilesLoading: state.checklists.filesLoading
  };
};

export default connect(mapStateToProps, null)(ChecklistsPage);
