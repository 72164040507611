import {
  ADD_CORPORATE,
  CLEAR_CORPORATES_MESSAGES,
  CORPORATES_ERROR,
  CORPORATES_LOADING,
  corporatesDispatchTypes,
  DELETE_CORPORATE,
  GET_CORPORATES_INFORMATION,
  UPDATE_CORPORATE,
  GET_CORPORATE,
} from "../types/corporatesTypes";

interface DefaultStateI {
  corporates?: any;
  error?: string;
  loading?: boolean;
  success?: string;
  corporate?: any;
}

const initialState: DefaultStateI = {
  corporates: [],
  error: "",
  loading: true,
  success: "",
  corporate: {},
};

export const corporatesReducer = (
  state: DefaultStateI = initialState,
  action: corporatesDispatchTypes
): DefaultStateI => {
  switch (action.type) {
  case GET_CORPORATES_INFORMATION:
    return {
      ...state,
      corporates: action.payload,
      loading: false,
    };
  case GET_CORPORATE:
    return {
      ...state,
      corporate: action.payload,
      loading: false,
    };
  case ADD_CORPORATE:
    return {
      ...state,
      success: action.payload,
      loading: false,
    };
  case UPDATE_CORPORATE:
    return {
      ...state,
      success: action.payload,
      loading: false,
    };
  case DELETE_CORPORATE:
    return {
      ...state,
      success: action.payload,
      loading: false,
    };
  case CORPORATES_ERROR:
    return {
      ...state,
      error: action.payload,
      loading: false,
    };
  case CORPORATES_LOADING:
    return {
      ...state,
      error: action.payload,
      loading: true,
    };
  case CLEAR_CORPORATES_MESSAGES:
    return {
      ...state,
      error: "",
      success: "",
    };

  default:
    return state;
  }
};

export default corporatesReducer;
