import "./Pagination.css";

import paginateArrow from "img/pagination_arrow.png";
import React, {useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

type PaginationProps = {
  postsPerPage: number;
  totalPosts: number;
  paginate: any;
  limit?: number
  changeLimits?: (limit:number) => void
};

export default function Companies({
  postsPerPage,
  totalPosts,
  paginate,
  limit,
  changeLimits
}: PaginationProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [firstItem, setFirstItem] = useState(0);
  const [lastItem, setLastItem] = useState(postsPerPage);
  const [showLimitsMenu, setShowLimitsMenu] = useState(false);

  useEffect(() => {
    setLastItem(postsPerPage);
  }, [postsPerPage]);
  const [selectedPage, setSelectedPage] = useState(1);


  const paginationMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event: any) => {
      if (!paginationMenuRef.current?.contains(event.target as Node)) {
        setShowLimitsMenu(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });


  const changePage = (number: any) => {
    setSelectedPage(number);
    paginate(number);
    if (number === 0) {
      setFirstItem(0);
      setLastItem(postsPerPage);
    } else {
      setFirstItem((number - 1) * postsPerPage);
      setLastItem(number * postsPerPage);
    }
  };

  const pageNumbers = [];
  const prevPage = () => {
    selectedPage === 1
      ? changePage(selectedPage)
      : changePage(selectedPage - 1);
  };

  const nextPage = () => {
    selectedPage === pageNumbers.length
      ? changePage(selectedPage)
      : changePage(selectedPage + 1);
  };

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <div className="pagination-footer-container space-between">
      <div className="pagination-footer-info row">
        <p>
          {t("companies_page.home_showing")}
          <span className="pagination-footer-count">
            {firstItem + 1}-{totalPosts < lastItem ? totalPosts : lastItem}
          </span>
          <span className="pagination-footer-text">
            {t("companies_page.home_of")}
          </span>
          <span className="pagination-footer-count">{totalPosts}</span>
        </p>
      </div>
      <div className="row">
        {limit && changeLimits
          ?
          <div
            className="change_limit_box"
            onClick={() => setShowLimitsMenu(!showLimitsMenu)}
          >
            <p>
              {t("companies_page.pagination_showing_items")}
              <span style={{cursor: "pointer"}}>
                {limit}{t("companies_page.lines")}
              </span>
            </p>
            {showLimitsMenu
              ?
              <div className="change_limit_menu" ref={paginationMenuRef}>
                <div className="change_limit_item" onClick={() => changeLimits(8)}>
                  8 {t("companies_page.lines")}
                </div>
                <div className="change_limit_item" onClick={() => changeLimits(15)}>
                  15 {t("companies_page.lines")}
                </div>
                <div className="change_limit_item" onClick={() => changeLimits(30)}>
                  30 {t("companies_page.lines")}
                </div>
                <div className="change_limit_item" onClick={() => changeLimits(50)}>
                  50 {t("companies_page.lines")}
                </div>
              </div>
              : null
            }
          </div>
          : null
        }
        <div className="row">
          <div className="pagination-page center" onClick={() => prevPage()}>
            <img src={paginateArrow} alt="arrow-left" />
          </div>
          <div className="pagination-page center" onClick={() => nextPage()}>
            <img src={paginateArrow} alt="arrow-right" />
          </div>
        </div>
      </div>
    </div>
  );
}
