import { sidebarReducer } from "./sidebarReducer";
import { combineReducers } from "redux";
import { changeRowsReducer } from "./selectedUsersFromListReducer";
import { postDownloadFileReducer } from "./downloadFilesReducer";
import { usersReducer } from "./usersReducer";
import { rolesReducer } from "./rolesReducer";
import { authReducer } from "./authReducer";
import { corporatesReducer } from "./corporatesReducer";
import { checklistsReducer } from "./checkListsReducer";
import { getCampaignReducer } from "./campaignReducer";
import {checkBoxReducer} from "./employeeCheckboxReducer";

export const rootReducer = combineReducers({
  sidebar: sidebarReducer,
  selectedRows: changeRowsReducer,
  downloadFileState: postDownloadFileReducer,
  auth: authReducer,
  roles: rolesReducer,
  users: usersReducer,
  corporates: corporatesReducer,
  checklists: checklistsReducer,
  employeeCheckboxesState: checkBoxReducer,
  campaign:getCampaignReducer
});

export type RootState = ReturnType<typeof rootReducer>;
