import DeleteFromLists from "Components/Modals/DeleteFromLists";
import CustomToastContainer from "Components/SimpleComponents/ToastContainer";
import React, { lazy, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "redux/reducers/rootReducer";
import {
  clearCorporatesMessages,
  deleteCorporate,
  getCorporates,
} from "../../redux/actions/corporatesActions";
import ContentContainer from "../Containers/ContentContainer";
import MainSpace from "../Containers/MainSpace";
import SearchBarLight from "../SimpleComponents/SearchBarLight";

const ButtonWithIcon = lazy(() => import("../Buttons/ButtonWithIcon"));
const BenefitsCompaniesList = lazy(
  () => import("../Lists/BenefitsCompaniesList")
);
const CorporatesDetail = lazy(() => import("./CorporatesDetail"));

// Styles for modal window
const customStyles = {
  overlay: {
    backgroundColor: "rgba(55,52,52,0.7)",
    zIndex: 9999,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
if (process.env.NODE_ENV !== "test") Modal.setAppElement("#root");

interface CustomProps {
  corporate?: any;
}

function Benefits({ corporate }: CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showDetailPage, setShowDetailPage] = useState(false);

  // Show modal for submit delete company
  const [showSubmitModal, setShowSubmitModal] = useState(false);

  const [deletingCandidateId, setDeletingCandidateId] = useState("");

  const [searchValue, setSearchValue] = useState("");

  // Handle company search
  const handleSearch = (value: string) => {
    dispatch(getCorporates(8, 0, value));
    setSearchValue(value);
  };


  //delete corporate by its ID
  const deleteHandler =()=>{
    dispatch(deleteCorporate(deletingCandidateId));
  };


  // rerender the list of companies
  useEffect(() => {
    if (corporate && corporate.success === "delete_corporate") {
      dispatch(clearCorporatesMessages());
      dispatch(getCorporates(8, 0));
      toast.success(
        <CustomToastContainer
          message={t("dashboard.benefits.toast.success_delete")}
          status={1}
        />,
        {
          autoClose: 2000,
        }
      );
    }
  }, [dispatch, corporate]);

  return showDetailPage ? (
    <CorporatesDetail backToPage={() => setShowDetailPage(false)} />
  ) : (
    <ContentContainer>
      <Modal style={customStyles} isOpen={showSubmitModal}>
        <DeleteFromLists
          closeModal={() => setShowSubmitModal(false)}
          deleteHandler={()=>deleteHandler()}
        />
      </Modal>
      <MainSpace>
        <div className="space-between">
          <h2 data-testid="titel-header-id">{t("dashboard.benefits.title")}</h2>
          <ButtonWithIcon
            paddingTop="15px"
            buttonType="primary"
            buttonIcon="add_company"
            buttonHandler={() => setShowDetailPage(true)}
          />
        </div>
        <div className="flex-end" style={{ marginTop: "20px" }}>
          <SearchBarLight 
            searchPlaceholder={t("dashboard.employee.search_placeholder")}
            handleSearchBar={(value: string) => handleSearch(value)}
          />
        </div>
        <BenefitsCompaniesList
          setShowSubmitModal={() => setShowSubmitModal(true)}
          setDeletingCandidateId={(value: string) =>
            setDeletingCandidateId(value)
          }
          searchValue={searchValue}
        />
      </MainSpace>
    </ContentContainer>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    corporate: state.corporates,
  };
};
export default Benefits;
connect(mapStateToProps, null);
