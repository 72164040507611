import { useFormik } from "formik";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import { toast } from "react-toastify";
import * as Yup from "yup";

import {
  clearAuthMessages,
  resetPassword,
} from "../../redux/actions/authActions";
import { RootState } from "../../redux/reducers/rootReducer";
import Button from "../Buttons/Button";
import FormikInput from "../SimpleComponents/FormikInput";
import CustomToastContainer from "../SimpleComponents/ToastContainer";
import ForgotPasswordSuccess from "./ForgotPasswordSuccess";

interface CustomProps {
  backToLogin: () => void;
  resetResponse?: any;
}

function ForgotPassword({ backToLogin, resetResponse }: CustomProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showSuccessBlock, setShowSuccessBlock] = useState(false);

  // show loading while sending data
  const [showLoading, setShowLoading] = useState(false);

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("login.email_error"))
        .required(t("login.required")),
    }),
    // handle form submitting
    onSubmit: () => console.log("CLICK"),
  });

  // listening for Error/Success after sending post req
  useEffect(() => {
    if (resetResponse.error === "reset_password") {
      if (resetResponse.error === 422) {
        setShowLoading(false);
        toast.error(
          <CustomToastContainer
            message={t("warnings.reset_not_user")}
            status={3}
          />
        );
        dispatch(clearAuthMessages());
      }
    } else if (resetResponse.success === "reset_password") {
      setShowLoading(false);
      setShowSuccessBlock(true);
      dispatch(clearAuthMessages());
    }
  }, [resetResponse.error, resetResponse.success, dispatch, t]);

  const handleLoginSubmit = (e: any) => {
    e.preventDefault();
    if (!formik.errors.email) {
      dispatch(resetPassword({ email: formik.values.email, domain: "admin" }));
      setShowLoading(true);
    } else {
      toast.error(
        <CustomToastContainer message={t("login.email_error")} status={3} />,
        {
          autoClose: 2000,
        }
      );
    }
  };
  return showSuccessBlock ? (
    <ForgotPasswordSuccess
      resetLink={(e) => handleLoginSubmit(e)}
      backToLogin={backToLogin}
      email={formik.values.email}
    />
  ) : (
    <form onSubmit={(e) => handleLoginSubmit(e)}>
      <h2 className="forgot_title">{t("login.forgot_title")}</h2>

      <div style={{ position: "relative" }}>
        <FormikInput
          htmlFor="email"
          name="email"
          value={formik.values.email}
          disabled={false}
          handleChange={formik.handleChange}
          onBlur={formik.handleBlur}
          label={t("login.email_placeholder")}
          placeholder={t("login.forgot_placeholder")}
        />
        {formik.errors.email && formik.touched.email && (
          <motion.p
            animate={{ y: 5 }}
            className="input_error"
            style={{ fontSize: "10px" }}
          >
            {formik.errors.email}
          </motion.p>
        )}
      </div>

      {showLoading ? (
        <div className="center login_btn btn_full">
          <BeatLoader size="10px" color="white" />
        </div>
      ) : (
        <Button
          buttonType="login_btn"
          buttonHtmlType="submit"
          buttonLabel={t("login.forgot_btn")}
          submitButton={true}
          fullWidth={true}
        />
      )}
      <div className="forgot_line" />
      <h3 onClick={backToLogin} className="remember-text">
        {t("login.back_btn")}
      </h3>
    </form>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    resetResponse: state.auth,
  };
};

export default connect(mapStateToProps, null)(ForgotPassword);
