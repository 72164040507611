import React, {useEffect, useState} from "react";
import axios from "axios";
import {prepareImage} from "../../redux/actions/prepareImage";

interface CustomProps {
  imageUrl: string,
  small?: boolean
}

export default function UserAvatar ({imageUrl, small}:CustomProps) {
  const [currentImage, setCurrentImage] = useState("");
  // CHECK IMAGE EXIST
  const [isImageExist, setIsImageExist] = useState(false);

  useEffect(() => {
    const getImage = async () => {
      await axios.get(currentImage).then((value:any) => {
        if (value.status <= 201) {
          setIsImageExist(true);
        } else {
          setCurrentImage("/images/user_avatar.svg");
        }
      }).catch((error) => setIsImageExist(false));
    };
    getImage();
    //Need to disable it, because eslint says, that I need to add
    //uploadedImage, but it will broke a logic
    // eslint-disable-next-line
  }, [currentImage])


  useEffect(() => {
    async function fetchData() {
      if (imageUrl !== "undefined") {
        try {
          const response = await prepareImage(imageUrl);
          setCurrentImage(response);
          setIsImageExist(true);
        } catch (e) {
          setCurrentImage("/images/user_avatar.svg");
        }
      } else {
        setCurrentImage("/images/user_avatar.svg");
      }
    }
    fetchData();
  }, [imageUrl]);

  return(
    <div
      className={small ? "update_info_img small" : "update_info_img"}
      style={{backgroundImage: `url(${currentImage})`}}
    />
  );
}
