import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Countdown from "react-countdown";

interface CustomProps {
  email: string;
  backToLogin: () => void;
  resetLink: (e: any) => void;
}

export default function ForgotPasswordSuccess({
  email,
  backToLogin,
  resetLink,
}: CustomProps) {
  const { t } = useTranslation();

  const [showCounter, setShowCounter] = useState(false);

  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    if (completed) {
      setShowCounter(false);
      return <span>DONE</span>;
    } else {
      return <span className="resend_counter">{seconds}</span>;
    }
  };

  const resendLink = (e: any) => {
    setShowCounter(true);
    resetLink(e);
  };

  return (
    <>
      <h2 className="forgot_title">{t("login.forgot_success_title")}</h2>
      <h3 className="forgot_success_text">
        {t("login.forgot_success_text_1")}
      </h3>
      <h3
        className="forgot_success_text"
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        {email}
      </h3>
      <h3 className="forgot_success_text">
        {t("login.forgot_success_text_2")}
        <strong>{t("login.forgot_success_text_3")}</strong>
      </h3>
      <div className="forgot_line" />
      <div className="space-between">
        <h3 onClick={backToLogin} className="forgot_back">
          {t("login.forgot_success_btn_1")}
        </h3>
        {showCounter ? (
          <>
            <Countdown date={Date.now() + 10000} renderer={renderer} />
          </>
        ) : (
          <h3 onClick={resendLink} className="forgot_back">
            {t("login.forgot_success_btn_2")}
          </h3>
        )}
      </div>
    </>
  );
}
