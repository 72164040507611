import editIcon from "img/Dashboard/Employee/edit_employee.svg";
import downloadIcon from "img/Dashboard/Checklists/download.svg";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";

import {
  changeChecklistStatus,
  clearChecklistsMessages,
  downloadChecklistsFiles,
  showChecklistsFilesLoading,
} from "../../redux/actions/checkListsActions";
import { RootState } from "../../redux/reducers/rootReducer";
import Checkbox from "./Checkbox";
import {addChangelog} from "../../API/checklistApi";

interface CustomProps {
  checklistsData: any;
  messages?: string;
  skip: number;
}

function ActionDotsChecklists({ checklistsData, messages, skip }: CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [menuListOpen, setMenuListOpen] = useState(false);

  // Show loading while request not finished
  const [showLoading, setShowLoading] = useState(false);

  // Detect click outside for close menu
  const EmployeeActionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event: any) => {
      if (!EmployeeActionsRef.current?.contains(event.target as Node)) {
        setMenuListOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  // Hide loading after getting response
  useEffect(() => {
    if (messages?.length) {
      setShowLoading(false);
      dispatch(clearChecklistsMessages());
    }
  }, [messages]);

  // States for checkboxes
  const [selectedValue, setSelectedValue] = useState(0);
  const handleCheckboxChange = (val: any) => {
    setShowLoading(true);
    setSelectedValue(+val);
    setMenuListOpen(false);
    dispatch(changeChecklistStatus(checklistsData._id, +val));
  };

  // Initialize selected status for checkbox
  useEffect(() => {
    setSelectedValue(checklistsData.status);
  }, [checklistsData]);

  // Handle Download files Click
  const handleDownloadFiles = () => {
    dispatch(showChecklistsFilesLoading());
    dispatch(downloadChecklistsFiles(checklistsData._id, checklistsData.partner));
  };

  const getType = () => {
    switch (checklistsData.type) {
    case 0:
      return "werbepartner";
    case 1:
      return "interne-benefits";
    case 2:
      return "corporate";
    case 3:
      return "b2b";
    case 4:
      return "local";
    }
  };

  return showLoading ? (
    <BeatLoader size="8px" />
  ) : (
    <div ref={EmployeeActionsRef} className="action_dots_container">
      <div
        onClick={() => setMenuListOpen(!menuListOpen)}
        className="action_container"
      >
        <div className="action_dot" />
        <div className="action_dot" />
        <div className="action_dot" />
      </div>
      {menuListOpen && (
        <div className="table_menu shadow">
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://checklist.corplife.at/${getType()}/${checklistsData._id}`}
              className="table_menu_item row"
            >
              <img src={editIcon} alt="info" />
              <p>{t("dashboard.checklists.actions.edit")}</p>
            </a>
          </div>
          <div
            onClick={handleDownloadFiles}
            className="table_menu_item row"
          >
            <img src={downloadIcon} alt="info" />
            <p>{t("dashboard.checklists.actions.download")}</p>
          </div>
          <div
            onClick={() => handleCheckboxChange("0")}
            className="table_menu_item row"
          >
            <Checkbox
              id={"0"}
              handleCheckboxChange={handleCheckboxChange}
              checked={selectedValue === 0}
            />
            <p>{t("dashboard.checklists.actions.new")}</p>
          </div>
          <div
            onClick={() => handleCheckboxChange("1")}
            className="table_menu_item row"
          >
            <Checkbox
              id={"1"}
              handleCheckboxChange={handleCheckboxChange}
              checked={selectedValue === 1}
            />
            <p>{t("dashboard.checklists.actions.implementation")}</p>
          </div>
          <div
            onClick={() => handleCheckboxChange("2")}
            className="table_menu_item row"
          >
            <Checkbox
              id={"2"}
              handleCheckboxChange={handleCheckboxChange}
              checked={selectedValue === 2}
            />
            <p>{t("dashboard.checklists.actions.rejected")}</p>
          </div>
          <div
            onClick={() => handleCheckboxChange("3")}
            className="table_menu_item row"
          >
            <Checkbox
              id={"3"}
              handleCheckboxChange={handleCheckboxChange}
              checked={selectedValue === 3}
            />
            <p>{t("dashboard.checklists.actions.done")}</p>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    messages: state.checklists.message,
  };
};

export default connect(mapStateToProps, null)(ActionDotsChecklists);
