import Button from "Components/Buttons/Button";
import Spacer from "Components/SimpleComponents/Spacer";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import closeIcon from "../../img/Dashboard/Employee/close_btn.svg";
import deleteTrashIcon from "../../img/Dashboard/Employee/delete_warning_image.svg";

interface CustomProps {
  closeModal: () => void;
  deleteHandler:()=>void;
}

function DeleteFromLists({ closeModal,deleteHandler }: CustomProps) {
  const { t } = useTranslation();


  return (
    <div className="modal_container">
      <div className="center">
        <h4>{t("dashboard.campaign.modal.title")}</h4>
      </div>
      <Spacer spacerHeight="30px" />
      <div className="center">
        <img src={deleteTrashIcon} alt="trash" />
      </div>
      <img
        className="close_popup"
        src={closeIcon}
        alt="close"
        onClick={closeModal}
        style={{ cursor: "pointer" }}
      />
      <p
        className="text_center"
        style={{ fontWeight: "normal", fontSize: "0.8em", width: "56em" }}
      >
        {t("dashboard.campaign.modal.text")}
      </p>
      <Spacer spacerHeight="20px" />
      <div onClick={closeModal} className="center">
        <Button
          buttonStyles={{ minWidth: "140px" }}
          buttonType="primary"
          buttonLabel={t("dashboard.campaign.modal.submit_btn")}
          buttonHandler={deleteHandler}
        />
      </div>
      <Spacer spacerHeight="10px" />
      <div className="center">
        <h4 onClick={closeModal} style={{ cursor: "pointer" }}>
          {t("dashboard.campaign.modal.cancel_btn")}
        </h4>
      </div>
    </div>
  );
}

export default DeleteFromLists;
