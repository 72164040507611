import axios from "axios";
import uploadPlaceholder from "img/Dashboard/upload_placeholder.svg";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import environment from "../../environment";
import editPen from "../../img/icon-pencil.svg";
import { prepareImage } from "../../redux/actions/prepareImage";
import { isAllowed } from "../../utils/permissionsAllow";
import { getToken } from "../../utils/cookies/tokensCookie";
import CustomToastContainer from "./ToastContainer";
import {urls} from "../../API/urls";

interface CustomProps {
  ImageUrl: string;
  setImageUrl: (url: string) => void;
  update: (e: any, imageKey?: string) => void;
  companyImage?: any;
}

export default function ImageUpload({
  ImageUrl,
  setImageUrl,
  update,
  companyImage,
}: CustomProps) {
  const { t } = useTranslation();
  const [currentImage, setCurrentImage] = useState("");
  const [uploadedImage, setUploadedImage] = useState(false);

  useEffect(() => {
    setCurrentImage(ImageUrl);
    //Need to disable it, because eslint says, that I need to add
    //ImageUrl, but it will broke a logic
    // eslint-disable-next-line
  }, []);

  // CHECK IMAGE EXIST
  const [isImageExist, setIsImageExist] = useState(false);

  useEffect(() => {
    const getImage = async () => {
      if (ImageUrl && !uploadedImage) {
        await axios
          .get(ImageUrl)
          .then((value) => {
            if (value.status <= 201) {
              setIsImageExist(true);
            } else {
              setIsImageExist(false);
            }
          })
          .catch((error) => setIsImageExist(false));
      } else if (uploadedImage) {
        setIsImageExist(true);
      }
    };
    getImage();
    //Need to disable it, because eslint says, that I need to add
    //uploadedImage, but it will broke a logic
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (ImageUrl !== "/images/user_avatar.svg" && ImageUrl !== "undefined") {
        try {
          const response = await prepareImage(ImageUrl);
          setCurrentImage(response);
          setIsImageExist(true);
          setUploadedImage(true);
        } catch (e) {
          setCurrentImage("/images/user_avatar.svg");
        }
      } else {
        setCurrentImage("/images/user_avatar.svg");
      }
    }
    fetchData();
  }, [ImageUrl]);

  const handleFile = async (e: any) => {
    if (isAllowed()) {
      const file = e.target.files[0];
      if (file) {
        if (file.size > 1000000) {
          toast.error(t("warnings.image_size"));
        } else {
          setCurrentImage(URL.createObjectURL(file));
          const token = getToken();
          const options = { headers: { Authorization: `Bearer ${token}` } };
          const fullUrl = `${environment.baseUrl}${urls.files.files}`;
          const formData = new FormData();
          formData.append("file", file);
          formData.append("parameterName", "file");
          formData.append("mimeType", "image/jpeg");
          try {
            const uploadImage = await axios.post(fullUrl, formData, options);
            setImageUrl(uploadImage.data.key);
            update(undefined, uploadImage.data.key);
          } catch (e: any) {
            console.log(e);
          }
        }
      }
    } else {
      toast.warning(
        <CustomToastContainer message={t("warnings.not_allowed")} status={2} />
      );
    }
  };
  return (
    <div className="image_upload">
      <div
        className={companyImage ? "upload_image" : "upload_user"}
        style={
          isImageExist
            ? { backgroundImage: `url(${currentImage})` }
            : { backgroundImage: `url(${uploadPlaceholder})` }
        }
      ></div>
      <div className="custom_file_input center">
        <img src={editPen} alt="edit" />
      </div>
      <input
        onChange={handleFile}
        type="file"
        className="hide-file-input"
        accept=".jpg, .jpeg, .png"
      />
    </div>
  );
}
