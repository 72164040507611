import React, {useEffect, useState} from "react";
import "./ListStyles.css";
import Pagination from "../SimpleComponents/Pagination";
import {RootState} from "../../redux/reducers/rootReducer";
import {connect, useDispatch} from "react-redux";

import Spacer from "../SimpleComponents/Spacer";
import {changeSelectedRows} from "../../redux/actions/selectedUsersFromListActions";
import { useTranslation } from "react-i18next";
import {clearUserMessages, getUsers} from "../../redux/actions/usersActions";
import TableLoading from "../LoadingPlaceholders/TableLoading";
import EmptyTableState from "../SimpleComponents/TableEmptyState";
import {toast} from "react-toastify";
import CustomToastContainer from "../SimpleComponents/ToastContainer";
import {handleEmployeeCheckBox} from "../../redux/actions/employeeCheckboxActions";
import {getCorporates} from "../../redux/actions/corporatesActions";
import EmployeeActionsDots from "./ActionsDots/EmployeeActionsDots";

interface CustomProps {
  employee: any,
  searchData?: any,
  openModal: (state:boolean, data:any) => void,
  searchValue: string,
  usersMessagesState?: any,
  setNewUserModalState: (state:boolean) => void,
  search:string
}


function EmployeeList(
  {employee, searchData, usersMessagesState, setNewUserModalState,
    openModal, search}:CustomProps) {
  interface Lookup {
    [index: string]: string;
  }

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [pageLimit, setPageLimit] = useState(8);

  // Change limits of items in page
  const changePageLimit = (newLimit:number) => {
    if (newLimit !== pageLimit) {
      dispatch(getCorporates(newLimit, 0));
      setPageLimit(newLimit);
    }
  };

  useEffect(() => {
    if (!search.length) {
      dispatch(getUsers(pageLimit,0));
    }
  }, [dispatch]);

  useEffect(() => {
    if (employee.users) {
      setSortedData(employee.users);
    }
  }, [employee.users]);

  // refresh data after delete User
  useEffect(() => {
    if (usersMessagesState.success === "delete_user" || usersMessagesState.success) {
      dispatch(getUsers(pageLimit,0));
      dispatch(clearUserMessages());
      dispatch(handleEmployeeCheckBox([]));
      dispatch(changeSelectedRows([]));
    }
  }, [usersMessagesState.success,]);

  // Actions after delete Employee
  useEffect(() => {
    if (usersMessagesState.success === "delete_user") {
      dispatch(getUsers(pageLimit,0));
      toast.success(
        <CustomToastContainer
          message={t("warnings.success_user_deleted")}
          status={1} />, {
          autoClose: 2000,
        });
    }
    //Need to disable it, because eslint says, that I need to add
    //all dependencies from formik, but we need to update, when company
    // state is updated
    // eslint-disable-next-line
  }, [usersMessagesState.success, dispatch, t])

  // Action after add new employee
  useEffect(() => {
    if (usersMessagesState.success === "add_one_employee") {
      setNewUserModalState(false);
      dispatch(getUsers(pageLimit, 0));
      toast.success(
        <CustomToastContainer
          message={t("warnings.success_user_created")}
          status={1} />, {
          autoClose: 2000,
        });
      dispatch(clearUserMessages());
    } else if (usersMessagesState.error) {
      setNewUserModalState(false);
      if (usersMessagesState.error === "user_exist") {
        toast.error(
          <CustomToastContainer
            message={t("warnings.warning_user_exists")}
            status={3} />, {
            autoClose: 2000,
          });
        dispatch(clearUserMessages());
      } else {
        toast.error(
          <CustomToastContainer
            message={t("warnings.warning_server_error")}
            status={3} />, {
            autoClose: 2000,
          });
        dispatch(clearUserMessages());
      }
    }
  }, [usersMessagesState, dispatch, t]);

  // Actions after update Employee
  useEffect(() => {
    if (usersMessagesState.success === "update_employee") {
      dispatch(clearUserMessages());
      dispatch(getUsers(pageLimit,0));
      toast.success(
        <CustomToastContainer
          message={t("warnings.success_user_updated")}
          status={1} />, {
          autoClose: 2000,
        });
    } else if (usersMessagesState.error === "update_employee") {
      dispatch(clearUserMessages());
      toast.error(
        <CustomToastContainer
          message={t("warnings.warning_server_error")}
          status={3} />, {
          autoClose: 2000,
        });
    }
  }, [usersMessagesState, dispatch, t]);


  const [sortedData, setSortedData] = useState([]);
  useEffect(() => {
    if (searchData && searchData.length > 0) {
      setSortedData(searchData);
    }
  }, [searchData]);

  // set which sort is selected
  const [sortButton, setSortButton] = useState({
    firstName: "up",
    lastName: "up",
    email: "up"
  });

  const [sortValue, setSortValue] = useState("");
  const sortingData = (name:string) => {
    const sortButtonClone: Lookup = sortButton;
    const sortValue = sortButtonClone[name] === "up" ? "down" : "up";
    const postValue = `${sortValue === "up" ? "" : "-"}${name}`;
    setSortValue(postValue);
    dispatch(getUsers(pageLimit, 0));
    setSortButton({...sortButton, [name]: sortValue});
  };

  // handle Paginate
  const currentPosts = sortedData.slice(0, pageLimit);

  const paginate = (pageNumber: number) => {
    const skip = (pageNumber - 1) * pageLimit;
    dispatch(getUsers(pageLimit, skip));
  };

  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    if (employee.users) {
      setShowLoading(false);
    }
  }, [employee.users]);

  return(
    <div >
      {employee.users && employee.users.length === 0 ? (
        <EmptyTableState style={{height:"600px", backgroundColor: "transparent"}} />
      ) : (
        <div className="table_box">
          {showLoading
            ? <TableLoading />
            :
            <div className="table_wrapper" >
              <table style={{width:"100%"}}>
                <thead>
                  <tr>
                    <th className="pl-10">
                      <div className="row">
                        <Spacer spacerWidth="1rem" />
                        <p>{t("dashboard.home.employee_list_th_1")}</p>
                      </div>
                    </th>
                    <th>
                      <p>{t("dashboard.home.employee_list_th_2")}</p>
                    </th>
                    <th>
                      <p>{t("dashboard.home.employee_list_th_3")}</p>
                    </th>
                    <th>
                      <div className="responsive-mr-40">
                        {t("dashboard.home.employee_list_th_5")}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentPosts.map((item:any, index:number) => (
                    <React.Fragment key={item._id}>
                      <tr className="spacer" />
                      <tr className="table-item">
                        <td>
                          <div className="row">
                            <Spacer spacerWidth="1rem" />
                            <p className="list_check_text">
                              {item.firstName}</p>
                          </div>
                        </td>
                        <td>
                          {item.lastName}</td>
                        <td>
                          {item.email}</td>
                        <td>
                          <EmployeeActionsDots
                            userData={item}
                            openModal={openModal}
                          />
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          }
        </div>
      )}
      <Pagination
        postsPerPage={pageLimit}
        totalPosts={employee && employee.totalUser}
        paginate={paginate}
        limit={pageLimit}
        changeLimits={(limit:number) => changePageLimit(limit)}
      />
    </div>
  );
}
const mapStateToProps = (state:RootState) => {
  return {
    selectedRows: state.selectedRows.selectedRows,
    usersMessagesState: state.users
  };
};

export default connect(mapStateToProps, null)(EmployeeList);

