import "./App.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import React, {useEffect, useLayoutEffect, useState} from "react";
import { connect, useDispatch } from "react-redux";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import {BrowserRouter, Route, Routes, Navigate, Router} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ChangePassword from "./Components/Pages/ChangePassword";
import DashboardPage from "./pages/DashboardPage/DashboardPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import NotFoundPage from "./pages/NotFoundPage";
import ServerErrorPage from "./pages/ServerErrorPage";
import { userTokenDispatcher } from "./redux/actions/authActions";
import { analytics, performance } from "./services/firebase_setup";
import { getToken } from "./utils/cookies/tokensCookie";
import customHistory from "./customHistory";

function App() {
  // Language change for all pages
  const dispatch = useDispatch();
  useEffect(() => {
    const token = getToken();
    token && dispatch(userTokenDispatcher(token));
  }, [dispatch]);

  // INITIALIZE FIREBASE TOOLS
  // FOR ANALYTICS AND PERFORMANCE BASE
  useEffect(() => {
    analytics.logEvent("started");
    performance.dataCollectionEnabled;
    performance.instrumentationEnabled;
    // Initialize Sentry
    Sentry.init({
      dsn: "https://4add824489674cab9637101762302e73@o538122.ingest.sentry.io/5936865",
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  }, []);

  const CustomRouter = ({ history, ...props }:any) => {
    const [state, setState] = useState({
      action: history.action,
      location: history.location
    });

    useLayoutEffect(() => history.listen(setState), [history]);

    return (
      <Router
        {...props}
        location={state.location}
        navigationType={state.action}
        navigator={history}
      />
    );
  };

  return (
    <div className="App">
      <ToastContainer />
      <CustomRouter history={customHistory}>
        <Routes>
          <Route path="/" element={<Navigate to="/benefits" />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/resetPassword" element={<ChangePassword />}/>
          <Route path="/activateUser" element={<ChangePassword activateUser={true} />}/>
          <Route path="/error" element={<NotFoundPage />} />
          <Route path="/server-error" element={<ServerErrorPage />} />
          <Route path="/:Page" element={<DashboardPage />} />
          <Route path="/:Page/:Page" element={<DashboardPage />}/>
          <Route element={<Navigate to="/benefits" />} />
        </Routes>
      </CustomRouter>
    </div>
  );
}

const mapDispatchToProps = {
  userTokenDispatcher,
};

export default connect(null, mapDispatchToProps)(App);
