import errorIcon from "img/Dashboard/error_msg_icon.svg";
import successIcon from "img/Dashboard/success_msg_icon.svg";
import warningIcon from "img/Dashboard/warning_msg_icon.svg";
import React from "react";

interface CustomProps {
  message: any;
  status: number;
}

export default function CustomToastContainer({ message, status }: CustomProps) {
  let icon = "";
  switch (status) {
  case 1:
    icon = successIcon;
    break;
  case 2:
    icon = warningIcon;
    break;
  case 3:
    icon = errorIcon;
    break;
  }

  return (
    <div className="row">
      <img
        style={{ marginLeft: "0.5rem", marginRight: "1rem" }}
        src={icon}
        alt={status.toString()}
      />
      <p>{message}</p>
    </div>
  );
}
