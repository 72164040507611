export const GET_CHECKLISTS_SUCCESS = "GET_CHECKLISTS_SUCCESS";
export const UPDATE_CHECKLIST_STATUS_SUCCESS =
  "UPDATE_CHECKLIST_STATUS_SUCCESS";
export const DOWNLOAD_FILES = "DOWNLOAD_FILES";
export const CHECKLISTS_LOADING = "CHECKLISTS_LOADING";
export const CHECKLISTS_FILES_LOADING = "CHECKLISTS_FILES_LOADING";
export const CHECKLISTS_ERROR = "CHECKLISTS_ERROR";
export const CLEAR_CHECKLISTS_MESSAGES = "CLEAR_CHECKLISTS_MESSAGES";

export interface getChecklistsSuccess {
  type: typeof GET_CHECKLISTS_SUCCESS;
  payload: any;
}

export interface updateChecklistStatusSuccess {
  type: typeof UPDATE_CHECKLIST_STATUS_SUCCESS;
  payload: any;
}

export interface downloadFilesSuccess {
  type: typeof DOWNLOAD_FILES;
  payload: any;
}

export interface checkListsLoading {
  type: typeof CHECKLISTS_LOADING;
  payload: any;
}

export interface checkListsFilesLoading {
  type: typeof CHECKLISTS_FILES_LOADING;
  payload: any;
}

export interface checkListsError {
  type: typeof CHECKLISTS_ERROR;
  payload: any;
}

export interface clearChecklistMessages {
  type: typeof CLEAR_CHECKLISTS_MESSAGES;
  payload: string;
}

export type checklistsDispatchTypes =
  | getChecklistsSuccess
  | updateChecklistStatusSuccess
  | downloadFilesSuccess
  | checkListsLoading
  | checkListsFilesLoading
  | checkListsError
  | clearChecklistMessages;
