export const urls = {
  auth: {
    codeValidate: "codes/validate",
    resetPassword: "admin/general/resetPassword",
    resetEmail: "admin/general/resetEmail",
    login: "admin/auth/tokens"
  },
  benefits: {
    campaign: "benefit/campaign"
  },
  general: {
    checklists: "corplife/checklists",
    checklistsChangeStatus: "corplife/checklists/changeStatus",
    checklistsDownload: "corplife/checklists/download",
    checklistsChangelog: "admin/corplife/checklists/changelog",
    uploadXlsFileFoFlyer: "admin/flyer"
  },
  corporates: {
    corporates: "corporates"
  },
  files: {
    files: "files",
    generateReportForUser: "export/generateReportForUser"
  },
  users: {
    users: "admin/users"
  },
  lunch: {
    companies: {
      getAll: "admin/company"
    },
    roles: {
      getByCompanyId: "admin/roles/byCompanyId",
      get: "admin/roles",
      associatedCompanies: "admin/roles/associatedCompanies"
    }
  }
};
