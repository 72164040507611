import {
  CHANGE_SELECTED_ROWS,
  ChangeSelectionTypes,
} from "../types/selectedUsersFromListTypes";

const initialState = {
  selectedRows: [],
};

export const changeRowsReducer = (
  state = initialState,
  action: ChangeSelectionTypes
) => {
  switch (action.type) {
  case CHANGE_SELECTED_ROWS:
    return { selectedRows: action.payload };
  default:
    return state;
  }
};

export default changeRowsReducer;