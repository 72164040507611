import React, {useEffect, useState} from "react";
import {getToken} from "../../../../utils/cookies/tokensCookie";
import environment from "../../../../environment";
import {urls} from "../../../../API/urls";
import axios from "axios";
import {Select} from "antd";
import Button from "../../../../Components/Buttons/Button";

const { Option } = Select;

interface IProps {
  selectedCompany: {title: string; id: string} | null;
  onSelect: (user: {name: string; id: string}) => void;
}

export default function SelectRoleForAssociatedCompanies(props: IProps) {

  const [data, setData] = useState([]);
  const [selectedUser, setSelectedUser] = useState<null | {id: string, name: string}>(null);

  const getData = async () => {
    const token = getToken();
    // eslint-disable-next-line max-len
    const fullUrl = `${environment.baseUrl}${urls.lunch.roles.getByCompanyId}/${props.selectedCompany?.id}`;
    const options = {
      headers: {Authorization: `Bearer ${token}`},
      params: {
        limit: 999,
        skip: 0
      }
    };
    const request = await axios.get(fullUrl, options);
    setData(request.data.data);
  };

  // Get all roles for company
  useEffect(() => {
    if (props.selectedCompany) {
      getData();
    }
  }, [props.selectedCompany]);

  const handleChange = (value: string) => {
    const foundUser:any = data.filter((user:any) => user.id === value);
    if (foundUser) {
      setSelectedUser(foundUser[0]);
    }
  };

  console.log(selectedUser);

  return (
    <div>
      <h3>{`Select a user for ${props.selectedCompany?.title}`}</h3>
      <Select defaultValue="Select user" style={{ width: 300 }} onChange={handleChange}>
        {data.map((user: any) => (
          <Option
            key={user.id}
            value={user.id}
          >
            {user.name}
          </Option>
        ))}
      </Select>
      <div style={{height: "20px"}} />
      <Button
        buttonType={"primary"}
        buttonLabel={"Submit"}
        disabled={!selectedUser}
        buttonHandler={() => {
          if (selectedUser) {
            props.onSelect(selectedUser);
          }
        }}
      />
    </div>
  );
}
