import axios from "axios";
import qs from "qs";
import {Dispatch} from "redux";

import environment from "../../environment";
import {errorHandler} from "../../utils/errorHandler";
import {getToken} from "../../utils/cookies/tokensCookie";
import {
  CHECKLISTS_ERROR, CHECKLISTS_FILES_LOADING,
  CHECKLISTS_LOADING,
  checklistsDispatchTypes,
  CLEAR_CHECKLISTS_MESSAGES,
  DOWNLOAD_FILES,
  GET_CHECKLISTS_SUCCESS,
  UPDATE_CHECKLIST_STATUS_SUCCESS,
} from "../types/checkListsTypes";
import {getUserId} from "../../utils/cookies/userCookies";
import {urls} from "../../API/urls";

// Get Checklists
export const getChecklists =
  (limit: number, skip: number, search?: string) =>
    async (dispatch: Dispatch<checklistsDispatchTypes>) => {
      const token = getToken();
      const fullUrl = `${environment.baseUrl}${urls.general.checklists}`;
      const dynamicParams = {
        limit: limit,
        skip: skip,
      };
      if (search && search.length) {
        Object.assign(dynamicParams, { search: search });
      }
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        params: dynamicParams,
        paramsSerializer: (params: any) => qs.stringify(params),
      };
      try {
        const request = await axios.get(fullUrl, options);
        dispatch({
          type: GET_CHECKLISTS_SUCCESS,
          payload: request.data,
        });
      } catch (e: any) {
        if (!errorHandler(e.request.status)) {
          dispatch({
            type: CHECKLISTS_ERROR,
            payload: "get_corporates",
          });
        }
      }
    };

// Change status of checklist
export const changeChecklistStatus =
  (id: string, status: number) =>
    async (dispatch: Dispatch<checklistsDispatchTypes>) => {
      const token = getToken();
      const fullUrl = `${environment.baseUrl}${urls.general.checklistsChangeStatus}/${id}`;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        paramsSerializer: (params: any) => qs.stringify(params),
      };
      try {
        await axios.post(fullUrl, {status: status, userId: getUserId()}, options);
        dispatch({
          type: UPDATE_CHECKLIST_STATUS_SUCCESS,
          payload: "update_success",
        });
      } catch (e: any) {
        if (!errorHandler(e.request.status)) {
          dispatch({
            type: CHECKLISTS_ERROR,
            payload: "update_error",
          });
        }
      }
    };

// Download files
export const downloadChecklistsFiles = (id:string, name:string) =>
  async (dispatch: Dispatch<checklistsDispatchTypes>) => {
    const token = getToken();
    const fullUrl = `${environment.baseUrl}${urls.general.checklistsDownload}/${id}`;
    const fileName = `${name}_files.zip`;
    try {
      const response = await axios({
        url: fullUrl,
        method: "POST",
        responseType: "blob",
        headers: { Authorization: `Bearer ${token}` },
      });

      const xhr = new XMLHttpRequest();
      const a = document.createElement("a");
      let file:any;
      const url = window.URL.createObjectURL(
        new Blob([response.data],
          {type: "application/zip"})
      );
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = function () {
        file = new Blob([xhr.response], { type : "application/zip" });
        a.href = window.URL.createObjectURL(file);
        a.download = fileName;
        a.click();
      };
      xhr.send();
      dispatch({
        type: DOWNLOAD_FILES,
        payload: response,
      });
    } catch (e: any) {
      if (!errorHandler(e.request.status)) {
        dispatch({
          type: CHECKLISTS_ERROR,
          payload: "download_files",
        });
      }
    }
  };


export function showChecklistsLoading() {
  return {
    type: CHECKLISTS_LOADING,
  };
}

export function showChecklistsFilesLoading() {
  return {
    type: CHECKLISTS_FILES_LOADING,
  };
}

export function clearChecklistsMessages() {
  return {
    type: CLEAR_CHECKLISTS_MESSAGES,
  };
}
