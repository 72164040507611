import React, {useEffect, useRef, useState} from "react";
import editIcon from "img/Dashboard/Employee/edit_employee.svg";
import deleteIcon from "img/Dashboard/Employee/delete_employee.svg";
import {connect, useDispatch} from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import { useTranslation } from "react-i18next";
import {clearUserMessages, deleteOneUser, getUsers} from "../../../redux/actions/usersActions";
import {RootState} from "../../../redux/reducers/rootReducer";

interface CustomProps {
  userData: any
  openModal: (state:boolean, data:any) => void,
  deleteSuccess?: any
}

function EmployeeActionsDots(
  {userData, openModal, deleteSuccess}:CustomProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [menuListOpen, setMenuListOpen] = useState(false);
  // loading after delete
  const [showLoading, setShowLoading] = useState(false);

  // After User has been deleted from DB
  useEffect(() => {
    if (deleteSuccess.success === "delete_user" && showLoading) {
      setShowLoading(false);
      dispatch(getUsers(8,0));
      dispatch(clearUserMessages());
    }
  }, [deleteSuccess, dispatch, showLoading]);

  const handleOpenModalClick = () => {
    setMenuListOpen(false);
    openModal(true, userData);
  };
  const deleteUsersHandler = () => {
    setShowLoading(true);
    dispatch(deleteOneUser([userData._id]));
  };

  // Detect click outside for close menu

  const EmployeeActionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event:any) => {
      if (!EmployeeActionsRef.current?.contains(event.target as Node)) {
        setMenuListOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return(
    showLoading
      ? <BeatLoader size="8px" />
      :
      <div ref={EmployeeActionsRef} className="action_dots_container">
        <div
          onClick={() => setMenuListOpen(!menuListOpen)}
          className="action_container">
          <div className="action_dot" />
          <div className="action_dot" />
          <div className="action_dot" />
        </div>
        {menuListOpen && (
          <div className="table_menu shadow">
            <div
              onClick={handleOpenModalClick}
              className="table_menu_item row">
              <img src={editIcon} alt="edit"/>
              <p>{t("dashboard.employee.list.actions.update")}</p>
            </div>
            <div
              onClick={deleteUsersHandler}
              className="table_menu_item row">
              <img src={deleteIcon} alt="delete"/>
              <p>{t("dashboard.employee.list.actions.delete")}</p>
            </div>
          </div>
        )}
      </div>
  );
}

const mapStateToProps = (state:RootState) => {
  return {
    deleteSuccess: state.users
  };
};

export default connect(mapStateToProps, null)(EmployeeActionsDots);
