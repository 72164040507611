import {
  ADD_MANY_EMPLOYEE,
  ADD_ONE_EMPLOYEE,
  CLEAR_USERS_MESSAGES,
  DELETE_MANY_USERS,
  DELETE_ONE_USER,
  GET_ONE_USER,
  GET_USER_INFO,
  GET_USERS,
  INACTIVE_USERS,
  INVITE_USER_SUCCESS,
  UPDATE_EMPLOYEE,
  USER_STATISTIC,
  USERS_ERROR,
  usersTypes,
} from "../types/userTypes";

interface DefaultStateI {
  loading: boolean;
  error: string;
  success: string;
  users: any;
  userInformation: any;
  userStatistic: any;
  inactiveUsers: any;
  userInfo: any;
}

const initialState: DefaultStateI = {
  loading: true,
  error: "",
  success: "",
  users: [],
  userInformation: {},
  userStatistic: {},
  inactiveUsers: [],
  userInfo: {},
};

export const usersReducer = (
  state: DefaultStateI = initialState,
  action: usersTypes
): DefaultStateI => {
  switch (action.type) {
  case GET_USERS:
    return {
      ...state,
      users: action.payload,
      loading: false,
    };
  case GET_USER_INFO:
    return {
      ...state,
      userInfo: action.payload,
      loading: false,
    };
  case GET_ONE_USER:
    return {
      ...state,
      userInformation: action.payload,
      loading: false,
    };
  case USER_STATISTIC:
    return {
      ...state,
      userStatistic: action.payload,
      loading: false,
    };
  case INACTIVE_USERS:
    return {
      ...state,
      inactiveUsers: action.payload,
      loading: false,
    };
  case INVITE_USER_SUCCESS:
    return {
      ...state,
      success: action.payload,
      loading: false,
    };
  case ADD_MANY_EMPLOYEE:
    return {
      ...state,
      success: action.payload,
      loading: false,
    };
  case ADD_ONE_EMPLOYEE:
    return {
      ...state,
      success: action.payload,
      loading: false,
    };
  case UPDATE_EMPLOYEE:
    return {
      ...state,
      success: action.payload,
      loading: false,
    };
  case DELETE_ONE_USER:
    return {
      ...state,
      success: action.payload,
      loading: false,
    };
  case DELETE_MANY_USERS:
    return {
      ...state,
      success: action.payload,
      loading: false,
    };
  case USERS_ERROR:
    return {
      ...state,
      error: action.payload,
      loading: false,
    };
  case CLEAR_USERS_MESSAGES:
    return {
      ...state,
      error: "",
      success: "",
      loading: false,
    };
  default:
    return state;
  }
};


export default usersReducer;