import ContentContainer from "Components/Containers/ContentContainer";
import MainSpace from "Components/Containers/MainSpace";
import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";
import Modal from "react-modal";
import DownloadFilesModal from "../Modals/DownloadFilesLoading";
import Button from "../Buttons/Button";
import EmployeeList from "../Lists/EmployeeList";
import {getUsers, showUserLoading} from "../../redux/actions/usersActions";
import Spacer from "../SimpleComponents/Spacer";
import EmployeeModal from "../Modals/EmployeeModal";
import SearchBar from "../SimpleComponents/SearchBarLight";

// Styles for modal window
const customStyles = {
  overlay: {
    backgroundColor: "rgba(55,52,52,0.7)",
    zIndex: 9999,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
if (process.env.NODE_ENV !== "test") Modal.setAppElement("#root");


interface CustomProps {
  listsState?: any;
  loading?: boolean;
  employee?: any
}

function EmployeePage({ listsState, loading, employee }: CustomProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();


  // Show/Hide for add new employee
  const [showModal, setShowModal] = useState(false);

  // Search by employee
  const [searchValue, setSearchValue] = useState("");
  const handleSearch = (value:string) => {
    setSearchValue(value);
    dispatch(showUserLoading());
    dispatch(getUsers(8,0, value));
  };

  //Handle submit delete users modal
  const [submitModal, setSubmitModal] = useState(false);
  const [deleteUsersArr, setDeleteUsersArray] = useState<any>([]);

  // data of selected user in actions
  const [selectedUserData, setSelectedUserData] = useState({});
  // close modal window from modal
  const handleModalWindow = (state:boolean, data:any) => {
    setNewUserModalState(true);
    setSelectedUserData(data);
  };

  // show add new user modal
  const [newUserModalState, setNewUserModalState] = useState(false);
  const addNewUserButtonHandler = () => {
    setNewUserModalState(true);
  };

  const closeModal = () => {
    setNewUserModalState(false);
    setSelectedUserData({});
  };

  // eslint-disable-next-line max-len
  const link = "https://datastudio.google.com/embed/reporting/f2ab2b54-a44b-42d3-804e-536d071519cc/page/tWDGB";

  return (
    <ContentContainer>
      <MainSpace>
        <div className="space-between">
          <h2>{t("dashboard.employee.title")}</h2>
          <Button
            buttonType="primary"
            buttonLabel={t("dashboard.employee.add_new_btn")}
            buttonHandler={addNewUserButtonHandler}
          />
        </div>

        <div className="flex-end" style={{marginTop: "20px"}}>
          <SearchBar
            searchPlaceholder={t("dashboard.employee.search_placeholder")}
            handleSearchBar={handleSearch}
          />
        </div>

        <Modal style={customStyles} isOpen={newUserModalState}>
          <EmployeeModal
            closeModal={closeModal}
            selectedUserData={selectedUserData}
          />
        </Modal>
        <Spacer spacerHeight="40px" />
        {loading
          ? (
            <p>Loading</p>
          )
          : (<EmployeeList
            search={searchValue}
            searchValue={searchValue}
            openModal={handleModalWindow}
            employee={employee}
            setNewUserModalState={(state:boolean) => setNewUserModalState(state)}
          />)}
      </MainSpace>
    </ContentContainer>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    employee: state.users.users,
    loading: state.users.loading
  };
};

export default connect(mapStateToProps, null)(EmployeePage);
