import "../SimpleComponents/Modal.css";

import React from "react";
import { useTranslation } from "react-i18next";

import closeIcon from "../../img/Dashboard/Employee/close_btn.svg";
import ChecklistsStatus from "../SimpleComponents/ChecklistsStatus";
import ArrowIcon from "../../img/Dashboard/Checklists/arrow-right-solid.svg";
import moment from "moment";
import UserAvatar from "../Containers/UserAvatar";

interface CustomProps {
  closeModal: () => void;
  changeLogData: any
}

export default function ChangelogModal({closeModal, changeLogData}: CustomProps) {
  const { t } = useTranslation();

  return (
    <div className="changelog_modal white">
      <img
        onClick={() => closeModal()}
        style={{ top: "0px" }}
        className="close_popup_paddings"
        src={closeIcon}
        alt="close"
      />
      <div className="changelog_wrapper">
        <ul className="sessions">
          {changeLogData.map((changelog:any, i:number) => (
            <li key={`${changelog.updatedAt}${i.toString()}`}>
              <div className="time">
                {moment(changelog.updatedAt).format("DD.MM.YYYY HH:mm")}
              </div>
              <div className="row" style={{marginTop: "7px"}}>
                <UserAvatar small imageUrl={changelog.updatedBy.image} />
                <p className="changelog_name" style={{
                  marginBottom: "3px",
                  marginLeft: "10px",
                  marginRight: "40px"
                }}
                >
                  {changelog.updatedBy.name}
                </p>
                {changelog.update.type === 0
                  ?
                  <div className="row">
                    <ChecklistsStatus type={+changelog.update.prevStatus} small />
                    <img src={ArrowIcon} alt="arrow" className="update_info_arrow"/>
                    <ChecklistsStatus type={+changelog.update.currStatus} small />
                  </div>
                  : null
                }
                {changelog.update.type === 1
                  ?
                  <div className="changelog_badge">
                    {t("dashboard.checklists.update_info.change_download")}
                  </div>
                  : null
                }
                {changelog.update.type === 2
                  ?
                  <div className="changelog_badge">
                    {t("dashboard.checklists.update_info.clicked_change")}
                  </div>
                  : null
                }
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
