export const GET_CORPORATES_INFORMATION = "GET_CORPORATES_INFORMATION";
export const GET_CORPORATE = "GET_CORPORATE";

export const ADD_CORPORATE = "ADD_CORPORATE";
export const UPDATE_CORPORATE = "UPDATE_CORPORATE";
export const DELETE_CORPORATE = "DELETE_CORPORATE";
export const CORPORATES_ERROR = "CORPORATES_ERROR";
export const CORPORATES_LOADING = "CORPORATES_LOADING";
export const CLEAR_CORPORATES_MESSAGES = "CLEAR_CORPORATES_MESSAGES";
export interface getCorporatesSuccess {
  type: typeof GET_CORPORATES_INFORMATION;
  payload: any;
}

export interface getCorporateSuccess {
  type: typeof GET_CORPORATE;
  payload: any;
}

export interface addCorporateSuccess {
  type: typeof ADD_CORPORATE;
  payload: string;
}

export interface updateCorporateSuccess {
  type: typeof UPDATE_CORPORATE;
  payload: string;
}

export interface deleteCorporateSuccess {
  type: typeof DELETE_CORPORATE;
  payload: string;
}

export interface corporatesError {
  type: typeof CORPORATES_ERROR;
  payload: any;
}

export interface corporatesLoading {
  type: typeof CORPORATES_LOADING;
  payload: any;
}

export interface clearCorporatesMessages {
  type: typeof CLEAR_CORPORATES_MESSAGES;
  payload: any;
}

export type corporatesDispatchTypes =
  | getCorporatesSuccess
  | getCorporateSuccess
  | addCorporateSuccess
  | updateCorporateSuccess
  | deleteCorporateSuccess
  | corporatesError
  | corporatesLoading
  | clearCorporatesMessages;
