import "./Checkbox.css";

import React from "react";

interface CustomInputProps {
  id: string;
  text?: string;
  checked?: boolean;
  handleCheckboxChange: (checked: boolean, id: string) => void;
}

export default function Checkbox({
  id,
  text,
  handleCheckboxChange,
  checked,
}: CustomInputProps) {
  const handleCheckbox = (e: any) => {
    e.preventDefault();
    handleCheckboxChange(!checked, id);
  };
  return (
    <div onClick={(e) => handleCheckbox(e)}>
      <input
        className={
          checked ? "checkbox_unchecked checkbox_checked" : "checkbox_unchecked"
        }
        id={id}
        type="checkbox"
      />
      <label className="remember-text" htmlFor={id}>
        {text}
      </label>
    </div>
  );
}
