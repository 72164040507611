import React, { useState } from "react";
import "./styles.css";
import { Tabs } from "antd";
import axios from "axios";
import { getToken } from "../../../utils/cookies/tokensCookie";
import environment from "../../../environment";
import { urls } from "../../../API/urls";
import { DOWNLOAD_FILES } from "../../../redux/types/checkListsTypes";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  addOneEmployee,
  updateEmployee
} from "../../../redux/actions/usersActions";
import * as Yup from "yup";
import FormikInput from "../../../Components/SimpleComponents/FormikInput";
import { motion } from "framer-motion";
import Button from "../../../Components/Buttons/Button";
import Modal from "react-modal";

const { TabPane } = Tabs;

export default function PdfGenerationPage() {
  const dispatch = useDispatch();

  const [showLoading, setShowLoading] = useState(false);

  const formik = useFormik<any>({
    initialValues: {
      store: "",
      storeCode: "",
      logo: "",
      content: "",
      background: "",
      theme: ""
    },
    validationSchema: Yup.object().shape({
      store: Yup.string().required("required"),
      storeCode: Yup.string(),
      logo: Yup.string().required("required"),
      content: Yup.string().required("required"),
      background: Yup.string().required("required"),
      theme: Yup.string()
    }),
    onSubmit: (values) => {
      sendForm(formik.values);
    }
  });

  const sendFile = async (event: any) => {
    setShowLoading(true);
    try {
      const formData = new FormData();
      formData.append("myfile", event.target.files[0]);

      const token = getToken();
      const fullUrl = `${environment.baseUrl}${urls.general.uploadXlsFileFoFlyer}`;

      axios({
        url: fullUrl,
        method: "POST",
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`
        },
        data: formData,
        responseType: "blob" // important
      }).then((response) => {
        setShowLoading(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "files.zip");
        document.body.appendChild(link);
        link.click();
      });
    } catch (e) {
      console.log(e);
    }
  };

  const sendForm = async (data: any) => {
    setShowLoading(true);
    try {
      const token = getToken();
      const fullUrl = `${environment.baseUrl}${urls.general.uploadXlsFileFoFlyer}`;

      axios({
        url: fullUrl,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: data,
        responseType: "blob" // important
      }).then((response) => {
        setShowLoading(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "files.zip");
        document.body.appendChild(link);
        link.click();
      });
    } catch (e) {
      console.log(e);
    }
  };

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(55,52,52,0.7)",
      zIndex: 9999
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "50%",
      transform: "translate(-50%, -50%)"
    }
  };

  return (
    <div
      style={{
        padding: "20px"
      }}
    >
      <Modal style={customStyles} isOpen={showLoading}>
        <p>Loading, for each entry it takes around 3 seconds</p>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/BTodGJB1ckA"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write;
                 encrypted-media; gyroscope; picture-in-picture; mute=1"
          allowFullScreen
        ></iframe>
      </Modal>
      <Tabs defaultActiveKey="0">
        <TabPane tab={"File upload"} key={"0"}>
          <input type="file" name="responseText" onChange={sendFile} />
        </TabPane>
        <TabPane tab={"Manual typing"} key={"1"}>
          <form onSubmit={formik.handleSubmit}>
            <div style={{ position: "relative" }}>
              <FormikInput
                htmlFor="store"
                name="store"
                value={formik.values.store}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={"store"}
                placeholder={"store"}
              />
              {formik.errors.store && formik.touched.store && (
                <motion.p
                  animate={{ y: 5 }}
                  className="input_error"
                  style={{ fontSize: "10px" }}
                >
                  Required
                </motion.p>
              )}
            </div>
            <div style={{ position: "relative" }}>
              <FormikInput
                htmlFor="storeCode"
                name="storeCode"
                value={formik.values.storeCode}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={"storeCode"}
                placeholder={"storeCode"}
              />
              {formik.errors.storeCode && formik.touched.storeCode && (
                <motion.p
                  animate={{ y: 5 }}
                  className="input_error"
                  style={{ fontSize: "10px" }}
                >
                  Required
                </motion.p>
              )}
            </div>
            <div style={{ position: "relative" }}>
              <FormikInput
                htmlFor="logo"
                name="logo"
                value={formik.values.logo}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={"logo"}
                placeholder={"logo"}
              />
              {formik.errors.logo && formik.touched.logo && (
                <motion.p
                  animate={{ y: 5 }}
                  className="input_error"
                  style={{ fontSize: "10px" }}
                >
                  Required
                </motion.p>
              )}
            </div>
            <div style={{ position: "relative" }}>
              <FormikInput
                htmlFor="content"
                name="content"
                value={formik.values.content}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={"content"}
                placeholder={"content"}
              />
              {formik.errors.content && formik.touched.content && (
                <motion.p
                  animate={{ y: 5 }}
                  className="input_error"
                  style={{ fontSize: "10px" }}
                >
                  Required
                </motion.p>
              )}
            </div>
            <div style={{ position: "relative" }}>
              <FormikInput
                htmlFor="background"
                name="background"
                value={formik.values.background}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={"background"}
                placeholder={"background"}
              />
              {formik.errors.background && formik.touched.background && (
                <motion.p
                  animate={{ y: 5 }}
                  className="input_error"
                  style={{ fontSize: "10px" }}
                >
                  Required
                </motion.p>
              )}
            </div>
            <div style={{ position: "relative" }}>
              <FormikInput
                htmlFor="theme"
                name="theme"
                value={formik.values.theme}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={"theme"}
                placeholder={"theme"}
              />
            </div>

            <Button
              buttonType="primary"
              buttonLabel={"Generate"}
              submitButton={true}
            />
          </form>
        </TabPane>
      </Tabs>
    </div>
  );
}
