import "./ChecklistsStatus.css";

import React from "react";
import { useTranslation } from "react-i18next";

interface CustomProps {
  type: any;
  small?: boolean
}

export default function ChecklistsStatus({ type, small }: CustomProps) {
  const { t } = useTranslation();

  let style;
  let text;
  switch (type) {
  case 0:
    style = "new";
    text = t("dashboard.checklists.status.new");
    break;
  case 1:
    style = "implementation";
    text = t("dashboard.checklists.status.implementation");
    break;
  case 2:
    style = "rejected";
    text = t("dashboard.checklists.status.rejected");
    break;
  case 3:
    style = "done";
    text = t("dashboard.checklists.status.done");
    break;
  default:
    style = "new";
    text = t("dashboard.checklists.status.new");
  }

  return (
    <div className={`badge ${style}_badge ${small ? "small" : ""}`}>
      <p>{text}</p>
    </div>
  );
}
