import "./TableEmptyState.css";

import emptyStateImg from "img/Dashboard/Orders/empty_table_img.svg";
import React from "react";
import { useTranslation } from "react-i18next";

interface CustomProps {
  style?: any;
}

export default function EmptyTableState({ style }: CustomProps) {
  const { t } = useTranslation();
  return (
    <div style={style} className="empty_table_container center">
      <div className="empty_table_box">
        <div className="center">
          <img src={emptyStateImg} alt="empty_table" />
        </div>
        <h2>{t("dashboard.overview.empty_table_title")}</h2>
        <p>{t("dashboard.overview.empty_table_text")}</p>
      </div>
    </div>
  );
}
