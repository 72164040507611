import {
  CHECKLISTS_ERROR,
  CHECKLISTS_LOADING,
  DOWNLOAD_FILES,
  checklistsDispatchTypes,
  GET_CHECKLISTS_SUCCESS,
  UPDATE_CHECKLIST_STATUS_SUCCESS,
  CLEAR_CHECKLISTS_MESSAGES, CHECKLISTS_FILES_LOADING,
} from "../types/checkListsTypes";

interface DefaultStateI {
  lists?: any;
  loading?: boolean;
  filesLoading?: boolean;
  message?: string;
}

const initialState = {
  lists: [],
  loading: true,
  message: "",
  filesLoading: false
};

export const checklistsReducer = (
  state: DefaultStateI = initialState,
  action: checklistsDispatchTypes
): DefaultStateI => {
  switch (action.type) {
  case GET_CHECKLISTS_SUCCESS:
    return {
      ...state,
      lists: action.payload,
      loading: false,
    };
  case UPDATE_CHECKLIST_STATUS_SUCCESS:
    return {
      ...state,
      message: action.payload,
      loading: false,
    };
  case DOWNLOAD_FILES:
    return {
      ...state,
      filesLoading: false,
    };
  case CHECKLISTS_LOADING:
    return {
      ...state,
      loading: true,
    };
  case CHECKLISTS_FILES_LOADING:
    return {
      ...state,
      filesLoading: true,
    };
  case CHECKLISTS_ERROR:
    return {
      ...state,
      message: action.payload,
      loading: false,
      filesLoading: false
    };
  case CLEAR_CHECKLISTS_MESSAGES:
    return {
      ...state,
      message: "",
      loading: false,
      filesLoading: false
    };
  default:
    return state;
  }
};

export default checklistsReducer;
