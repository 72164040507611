import {
  CHANGE_SIDEBAR_TAB,
  HANDLE_SIDEBAR,
  SidebarActionTypes,
} from "../types/sidebarTypes";

const initialState = {
  sidebarCon: false,
  sidebarTabCondition: "dashboard",
};


export const sidebarReducer = (
  state = initialState,
  action: SidebarActionTypes
) => {
  switch (action.type) {
  case HANDLE_SIDEBAR:
    return { ...state, sidebarCon: action.payload };
  case CHANGE_SIDEBAR_TAB:
    return { ...state, sidebarTabCondition: action.payload };
  default:
    return state;
  }
};

export default sidebarReducer;
