import React from "react";
import Button from "../../../../Components/Buttons/Button";
import {getToken} from "../../../../utils/cookies/tokensCookie";
import environment from "../../../../environment";
import {urls} from "../../../../API/urls";
import axios from "axios";
import {toast} from "react-toastify";
import CustomToastContainer from "../../../../Components/SimpleComponents/ToastContainer";
import {useTranslation} from "react-i18next";

interface IProps {
  selectedCompany: null | {id: string, title: string};
  selectedUser: null | {id: string, name: string};
  chosenCompanies: any[];
  onSubmit: () => void;
}

export default function FinalResultForAssociatedCompanies(props:IProps) {

  const { t } = useTranslation();

  const handleSave = async () => {
    const token = getToken();
    const fullUrl = `${environment.baseUrl}${urls.lunch.roles.associatedCompanies}`;
    const options = {headers: {Authorization: `Bearer ${token}`}};
    const uploadCompanies = props.chosenCompanies.map((company:any) => company.id);
    await axios.put(
      fullUrl,
      {
        roleId: props.selectedUser?.id,
        associatedCompanies: uploadCompanies
      },
      options
    )
      .then(() => {
        toast.success(
          <CustomToastContainer
            message={"Role Updated"}
            status={1} />, {
            autoClose: 2000,
          });
        props.onSubmit();
      })
      .catch((err:any) => {
        console.error(err);
        toast.error(
          <CustomToastContainer
            message={t("warnings.warning_server_error")}
            status={3} />, {
            autoClose: 2000,
          });
        props.onSubmit();
      });
  };


  return(
    <div>
      <p>
        {`HR Company: ${props.selectedCompany?.title}`}
      </p>
      <div style={{height: "10px"}} />
      <p>
        {`HR User: ${props.selectedUser?.name}`}
      </p>
      <div style={{height: "10px"}} />
      <p>Associated Companies:</p>

      <div className="row">
        {props.chosenCompanies.map((company: any) => (
          <div
            className="associated_company_badge"
            key={company.id}
          >
            {company.name}
          </div>
        ))}
      </div>

      <div style={{height: "10px"}} />

      <Button
        buttonType={"primary"}
        buttonLabel={"Save"}
        buttonHandler={() => {
          handleSave();
        }}
      />

    </div>
  );
}
