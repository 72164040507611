import React, {useEffect, useState} from "react";
import {getToken} from "../../../../utils/cookies/tokensCookie";
import environment from "../../../../environment";
import {urls} from "../../../../API/urls";
import axios from "axios";
import "./styles.css";
import {Select} from "antd";
import Button from "../../../../Components/Buttons/Button";

interface IProps {
  selectedRole: {id: string, name: string} | null;
  onSelect: (data: any[]) => void;
}

const { Option } = Select;

export default function ChoseCompaniesForAssociatedCompanies(props:IProps) {

  const [data, setData] = useState<any>([]);
  const [companiesData, setCompaniesData] = useState([]);

  const getData = async () => {
    const token = getToken();
    // eslint-disable-next-line max-len
    const fullUrl = `${environment.baseUrl}${urls.lunch.roles.get}/${props.selectedRole?.id}`;
    const options = {
      headers: {Authorization: `Bearer ${token}`}
    };
    const request = await axios.get(fullUrl, options);
    setData(request.data.data.associatedCompanies);
  };

  const getCompaniesData = async () => {
    const token = getToken();
    const fullUrl = `${environment.baseUrl}${urls.lunch.companies.getAll}`;
    const options = {
      headers: {Authorization: `Bearer ${token}`},
      params: {
        limit: 999,
        skip: 0
      }
    };
    const request = await axios.get(fullUrl, options);
    setCompaniesData(request.data.data);
  };

  useEffect(() => {
    if (props.selectedRole) {
      getData();
      getCompaniesData();
    }
  }, [props.selectedRole]);

  const removeItem = (itemId: string) => {
    const filteredResult = data.filter((company:any) => company.id !== itemId);
    setData(filteredResult);
  };

  const handleChange = (value: string) => {
    const foundCompany:any = companiesData.filter((company:any) => company._id === value);
    setData((oldArray:any) => [...oldArray, {
      id: foundCompany[0]._id,
      name: foundCompany[0].companyInfo.companyName
    }]);
  };

  return(
    <div>
      <p>Associated Companies: </p>
      <div className="row">
        {data.map((company: any) => (
          <div
            className="associated_company_badge"
            key={company.id}
          >
            {company.name}
            <p
              onClick={() => {
                removeItem(company.id);
              }}
            >
              x
            </p>
          </div>
        ))}
      </div>

      <div style={{height: "10px"}} />

      <p>Add new company to list</p>

      <Select defaultValue="Select company" style={{ width: 300 }} onChange={handleChange}>
        {companiesData.map((company: any) => (
          <Option
            key={company._id}
            value={company._id}
          >
            {company.companyInfo.companyName}
          </Option>
        ))}
      </Select>

      <div style={{height: "10px"}} />

      <Button
        buttonType={"primary"}
        buttonLabel={"Submit"}
        disabled={data.length < 1}
        buttonHandler={() => {
          if (data.length > 0) {
            props.onSelect(data);
          }
        }}
      />

    </div>
  );
}
