import Button from "Components/Buttons/Button";
import ContentContainer from "Components/Containers/ContentContainer";
import MainSpace from "Components/Containers/MainSpace";
import PageTitle from "Components/SimpleComponents/PageTitle";
import Spacer from "Components/SimpleComponents/Spacer";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";

import {
  changePassword,
  changePasswordFromEmail,
  clearAuthMessages,
} from "../../redux/actions/authActions";
import { RootState } from "../../redux/reducers/rootReducer";
import { getToken } from "../../utils/cookies/tokensCookie";
import FormikInput from "../SimpleComponents/FormikInput";
import CustomToastContainer from "../SimpleComponents/ToastContainer";
import {getUserId} from "../../utils/cookies/userCookies";
import {useNavigate} from "react-router-dom";

interface CustomProps {
  userEmail?: string;
  passwordChangeState?: any;
  activateUser?: boolean;
}

interface IUserInformation {
  newPassword: string;
  confirmPassword: string;
}

function ChangePassword({ passwordChangeState, activateUser }: CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [emailFromUrl, setEmailFromUrl] = useState("");

  useEffect(() => {
    const email = window.location.search;
    const indexOfStart = email.indexOf("=");
    setEmailFromUrl(email.slice(indexOfStart + 1, email.length));
  }, []);

  const formik = useFormik<IUserInformation>({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      newPassword: Yup.string().min(8, t("warnings.password_length")),
      confirmPassword: Yup.string()
        .min(8, t("warnings.password_length"))
        .when("newPassword", {
          is: (val: any) => !!(val && val.length > 0),
          then: Yup.string().oneOf(
            [Yup.ref("newPassword")],
            t("warnings.password_not_same")
          ),
        }),
    }),
    // handle form submitting
    onSubmit: () => console.log("ENTER KEY"),
  });

  useEffect(() => {
    if (passwordChangeState.success === "change_password") {
      dispatch(clearAuthMessages());
      toast.success(
        <CustomToastContainer
          message={t("warnings.password_change_ok")}
          status={1}
        />,
        {
          autoClose: 2000,
        }
      );
      setTimeout(() => {
        navigate("/");
      }, 1500);
    } else if (passwordChangeState.error === "change_password") {
      dispatch(clearAuthMessages());
      toast.error(
        <CustomToastContainer
          message={t("warnings.password_change_error")}
          status={3}
        />,
        {
          autoClose: 2000,
        }
      );
    } else if (passwordChangeState.error === "wrong_verification") {
      dispatch(clearAuthMessages());
      toast.error(
        <CustomToastContainer
          message={t("warnings.password_verification_expired")}
          status={3}
        />,
        {
          autoClose: 2000,
        }
      );
    }
    //Need to disable it, because eslint says, that I need to add
    //t and history as dependency but it will woke
    // that function in every render
    // eslint-disable-next-line
  }, [passwordChangeState.success, passwordChangeState.error]);

  const backButtonHandler = () => {
    const token = getToken();
    if (token) {
      navigate(
        "/general-info",
        {state: { prevPath: location.pathname }}
      );
    } else {
      navigate("/");
    }
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (getUserId()) {
      if (formik.values.newPassword === formik.values.confirmPassword) {
        if (emailFromUrl) {
          dispatch(
            changePassword({
              email: emailFromUrl,
              password: formik.values.confirmPassword,
            })
          );
        }
      } else {
        toast.error(
          <CustomToastContainer
            message={t("warnings.password_not_same")}
            status={3}
          />,
          {
            autoClose: 2000,
          }
        );
      }
    } else {
      if (
        formik.values.newPassword === formik.values.confirmPassword &&
        code.trim().length
      ) {
        if (emailFromUrl) {
          dispatch(
            changePasswordFromEmail(
              {
                email: emailFromUrl,
                password: formik.values.confirmPassword,
              },
              code
            )
          );
        }
      } else if (!code.trim().length) {
        toast.error(
          <CustomToastContainer
            message={t("warnings.password_code_not_provided")}
            status={3}
          />,
          {
            autoClose: 2000,
          }
        );
      } else {
        toast.error(
          <CustomToastContainer
            message={t("warnings.password_not_same")}
            status={3}
          />,
          {
            autoClose: 2000,
          }
        );
      }
    }
  };

  const [code, setCode] = useState("");

  return (
    <ContentContainer withoutHeader={true}>
      <MainSpace>
        <PageTitle
          mainTitle={
            activateUser
              ? t("change_password.activate_title")
              : t("change_password.title")
          }
        />

        <Spacer spacerHeight="31px" />

        <form onSubmit={(e) => handleSubmit(e)}>
          <div style={{ position: "relative" }}>
            <FormikInput
              htmlFor="password"
              name="newPassword"
              value={formik.values.newPassword}
              disabled={false}
              handleChange={formik.handleChange}
              label={
                activateUser
                  ? t("change_password.activate_password")
                  : t("dashboard.settings.change_pass_label_2")
              }
              onBlur={formik.handleBlur}
            />
            {formik.errors.newPassword && formik.touched.newPassword && (
              <p className="input_warning">{formik.errors.newPassword}</p>
            )}
          </div>
          <div style={{ position: "relative" }}>
            <FormikInput
              htmlFor="password"
              name="confirmPassword"
              value={formik.values.confirmPassword}
              disabled={false}
              handleChange={formik.handleChange}
              label={t("change_password.confirm_password")}
              onBlur={formik.handleBlur}
            />
            {formik.errors.confirmPassword &&
            formik.touched.confirmPassword && (
              <p className="input_warning">
                {formik.errors.confirmPassword}
              </p>
            )}
          </div>
          {getUserId() ? null : (
            <FormikInput
              htmlFor="code"
              name="code"
              value={code}
              disabled={false}
              handleChange={(e: any) => setCode(e.target.value)}
              label={t("change_password.verification_code_label")}
              onBlur={formik.handleBlur}
            />
          )}

          <Spacer spacerHeight="31px" />

          <div>
            <Button
              buttonType="tertiary"
              buttonLabel={t("change_password.back_btn")}
              buttonHtmlType="button"
              buttonHandler={backButtonHandler}
            />
          </div>
          <div className="text-right">
            <Button
              buttonType="primary"
              buttonLabel={t("change_password.submit_btn")}
              buttonHtmlType="submit"
              submitButton={true}
              disabled={!(formik.isValid && formik.dirty)}
            />
          </div>
        </form>

        <Spacer spacerHeight="120px" />
      </MainSpace>
    </ContentContainer>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    userEmail: state.users.userInfo.email,
    passwordChangeState: state.auth,
  };
};

export default connect(mapStateToProps, null)(ChangePassword);
