import React from "react";
import {Route, Routes} from "react-router-dom";
import AssociatedCompaniesSettings from "./AssociatedCompaniesSettings";

export default function CorplifeLunchRouter() {
  return (
    <Routes>
      <Route path="/" element={<AssociatedCompaniesSettings />} />
      <Route path="/createAssociatedCompanies" element={<AssociatedCompaniesSettings />} />
    </Routes>
  );
}
