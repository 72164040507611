import checklistsIcon from "img/Dashboard/SidebarIcons/icon_checklists.svg";
import companiesIcon from "img/Dashboard/SidebarIcons/icon_companies.svg";
import dashboardIcon from "img/Dashboard/SidebarIcons/icon_dashboard.svg";
import employeeIcon from "img/Dashboard/SidebarIcons/icon_employee.svg";
import onboardingIcon from "img/Dashboard/SidebarIcons/icon_onboarding.svg";
import ordersIcon from "img/Dashboard/SidebarIcons/icon_overview.svg";
import plansIcon from "img/Dashboard/SidebarIcons/icon_plans.svg";
import settingsIcon from "img/Dashboard/SidebarIcons/icon_settings.svg";
import React from "react";

interface CustomProps {
  handleSidebarChanges: (page: string) => void;
  selectedTab: string;
  itemName: string;
  itemText: string;
  openSidebar: boolean;
  subItem?: boolean;
}

function SidebarItem({
  handleSidebarChanges,
  selectedTab,
  itemName,
  itemText,
  openSidebar,
  subItem,
}: CustomProps) {
  // Setting image for tab
  let image = "";
  switch (itemName) {
  case "dashboard":
    image = dashboardIcon;
    break;
  case "campaigns":
    image = plansIcon;
    break;
  case "employee":
    image = employeeIcon;
    break;
  case "overview":
    image = ordersIcon;
    break;
  case "settings":
    image = settingsIcon;
    break;
  case "onboarding":
    image = onboardingIcon;
    break;
  case "companies":
    image = companiesIcon;
    break;
  case "checklists":
    image = checklistsIcon;
    break;
  default:
    image = dashboardIcon;
  }

  return (
    <li
      id="sidebar_item"
      style={subItem ? { paddingLeft: "20px" } : {}}
      className={
        selectedTab === itemName && subItem
          ? "sidebar_tab row selected_tab"
          : "row sidebar_tab"
      }
      onClick={() => handleSidebarChanges(itemName)}
    >
      <img id="dashboard_icon" src={image} alt={itemText} />
      {openSidebar ? (
        <p id="sidebar_text" className="sidebar_text">
          {itemText}
        </p>
      ) : null}
    </li>
  );
}

export default SidebarItem;
