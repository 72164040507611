import axios from "axios";
import qs from "qs";
import { Dispatch } from "redux";

import customHistory from "../../customHistory";
import environment from "../../environment";
import { errorHandler } from "../../utils/errorHandler";
import { getToken } from "../../utils/cookies/tokensCookie";

import {getUserCompany, getUserId} from "../../utils/cookies/userCookies";
import {
  ADD_ONE_EMPLOYEE,
  CLEAR_USERS_MESSAGES, DELETE_ONE_USER,
  GET_USER_INFO, GET_USERS, SHOW_USER_LOADING, UPDATE_EMPLOYEE,
  USERS_ERROR,
  usersTypes
} from "../types/userTypes";
import {urls} from "../../API/urls";

// Get user info
export const getUserInfo = () => async (dispatch: Dispatch<usersTypes>) => {
  const id = getUserId();
  const token = getToken();
  const fullUrl = `${environment.baseUrl}${urls.users.users}/`;

  const options = { headers: { Authorization: `Bearer ${token}` } };
  let userFromServer: any;

  try {
    const response = await axios.get(`${fullUrl}${id}`, options);
    dispatch({
      type: GET_USER_INFO,
      payload: response.data.user,
    });
  } catch (e: any) {
    if (!errorHandler(e.request.status)) {
      dispatch({
        type: USERS_ERROR,
        payload: "get_users",
      });
    }
  }
};

// Get Users
export const getUsers =
  (
    limit?: number,
    skip?: number,
    search?: string,
    sortBy?: string
  ) =>
    async (dispatch: Dispatch<usersTypes>) => {
      const token = getToken();
      const companyId = getUserCompany();
      const fullUrl = environment.baseUrl + `${urls.users.users}?`;
      const dynamicParams = {
        limit: limit,
        skip: skip,
      };
      if (companyId && companyId.length) {
        Object.assign(dynamicParams, { companyId: companyId });
      }
      if (search) {
        Object.assign(dynamicParams, { search: search });
      }
      if (sortBy) {
        Object.assign(dynamicParams, { sortBy: sortBy });
      }
      try {
        const response = await axios.get(fullUrl, {
          headers: { Authorization: `Bearer ${token}` },
          params: dynamicParams,
          paramsSerializer: (params: any) => qs.stringify(params),
        });
        dispatch({
          type: GET_USERS,
          payload: response.data,
        });
      } catch (e: any) {
        if (!errorHandler(e.request.status)) {
          dispatch({
            type: USERS_ERROR,
            payload: "get_users",
          });
        }
      }
    };

// Add one employee
export const addOneEmployee = (data:any) =>
  async (dispatch: Dispatch<usersTypes>) => {
    const token = getToken();
    const fullUrl = `${environment.baseUrl}${urls.users.users}`;
    const options = {headers: {"Authorization": `Bearer ${token}`}};
    try {
      await axios.post(fullUrl , data, options);
      dispatch({
        type: ADD_ONE_EMPLOYEE,
        payload: "add_one_employee"
      });
    } catch (e: any) {
      if (!errorHandler(e.request.status)) {
        dispatch({
          type: USERS_ERROR,
          payload: "user_exist"
        });
      }
    }
  };

// Update user
export const updateEmployee =
  (data: any, userId?: string) => async (dispatch: Dispatch<usersTypes>) => {
    const token = getToken();
    let user;
    if (userId) {
      user = userId;
    } else {
      user = getUserId();
    }
    const fullUrl = `${environment.baseUrl}${urls.users.users}/${user}`;
    const options = { headers: { Authorization: `Bearer ${token}` } };
    try {
      await axios.put(fullUrl, data, options).then(
        (response) => {
          dispatch({
            type: UPDATE_EMPLOYEE,
            payload: "update_employee",
          });
        },
        (error) => {
          dispatch({
            type: USERS_ERROR,
            payload: "update_employee",
          });
        }
      );
    } catch (e: any) {
      if (!errorHandler(e.request.status)) {
        dispatch({
          type: USERS_ERROR,
          payload: "update_employee",
        });
      }
    }
  };

// Delete one user
export const deleteOneUser =
  (userId: any) => async (dispatch: Dispatch<usersTypes>) => {
    const token = getToken();
    const fullUrl = `${environment.baseUrl}${urls.users.users}/${userId}`;
    const options = { headers: { Authorization: `Bearer ${token}` } };
    try {
      await axios.delete(fullUrl, options).then(
        (response) => {
          dispatch({
            type: DELETE_ONE_USER,
            payload: "delete_user",
          });
        },
        (error) => {
          if (error.request.status === 404) {
            customHistory.push("/error");
          } else if (error.request.status === 500) {
            customHistory.push("/server-error");
          }
        }
      );
    } catch (e: any) {
      if (!errorHandler(e.request.status)) {
        dispatch({
          type: USERS_ERROR,
          payload: "delete_user",
        });
      }
    }
  };

/*
// Delete many users
export const deleteUsers =
  (data: any) => async (dispatch: Dispatch<usersTypes>) => {
    const token = getToken();
    const fullUrl = `${environment.baseUrl}users/deleteMany`;
    const options = { headers: { Authorization: `Bearer ${token}` } };
    try {
      await axios.post(fullUrl, data, options).then(
        (response) => {
          dispatch({
            type: DELETE_MANY_USERS,
            payload: "delete_user",
          });
        },
        (error) => {
          if (error.request.status === 404) {
            customHistory.push("/error");
          } else if (error.request.status === 500) {
            customHistory.push("/server-error");
          }
        }
      );
    } catch (e: any) {
      if (!errorHandler(e.request.status)) {
        dispatch({
          type: USERS_ERROR,
          payload: "delete_user",
        });
      }
    }
  };
*/

// Show loading
export function showUserLoading() {
  return {
    type: SHOW_USER_LOADING,
  };
}

// Clear messages
export function clearUserMessages() {
  return {
    type: CLEAR_USERS_MESSAGES,
  };
}
