import "./i18next";
import "antd/dist/antd.css";
import "./index.css";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import DashboardLoading from "Components/LoadingPlaceholders/DashboardLoading";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./App";
import Store from "./redux/store";

if (!process.env.NODE_ENV || process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://9796f4a939654e2493e191f0c28e4189@o538122.ingest.sentry.io/5656069",
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing(),
      // showing real place of console.log
      new Sentry.Integrations.Breadcrumbs({ console: false }),
    ],

    // We recommend adjusting this value in production
    // for finer control
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <Provider store={Store}>
    <React.StrictMode>
      <Suspense fallback={<DashboardLoading />}>
        <App />
      </Suspense>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);
