import {
  DELETE_CAMPAIGN,
  ERROR_CAMPAIGN,
  getCampaignDispatcher,
  GET_CAMPAIGN,
  CLEAR_CAMPAIGN_MESSAGES,
  ADD_CAMPAIGN,
} from "redux/types/campaignType";

interface CustomState {
  campaign?: any;
  error?: any;
  success?:any
}

const InitialState: CustomState = {
  campaign: [],
  error: "",
  success:"",
};

export const getCampaignReducer = (
  state: CustomState = InitialState,
  action: getCampaignDispatcher
) => {
  switch (action.type) {
  case GET_CAMPAIGN:
    return {
      ...state,
      campaign: action.payload,
    };
  case ADD_CAMPAIGN:
    return {
      ...state,
      campaign: action.payload,
    };
  case ERROR_CAMPAIGN:
    return {
      ...state,
      error: action.payload,
    };
  case DELETE_CAMPAIGN:
    return{
      ...state,
      success: action.payload,
    };
  case CLEAR_CAMPAIGN_MESSAGES:
    return{
      ...state,
      success: "",
    };
  default:
    return state;
  }
};


export default getCampaignReducer;