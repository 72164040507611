export const GET_CAMPAIGN = "GET_CAMPAIGN";
export const ADD_CAMPAIGN = "ADD_CAMPAIGN";
export const ERROR_CAMPAIGN = "GET_CAMPAIGN";
export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN";
export const CLEAR_CAMPAIGN_MESSAGES = "CLEAR_CAMPAIGN_MESSAGES";





export interface getCampaign {
  type: typeof GET_CAMPAIGN;
  payload: any;
}

export interface addCampaign {
  type: typeof ADD_CAMPAIGN;
  payload: any;
}

export interface getCampaignError {
  type: typeof ERROR_CAMPAIGN;
  payload: any;
}

export interface deleteCampaignError {
  type: typeof DELETE_CAMPAIGN;
  payload: any;
}

export interface clearCampaignMassages {
  type: typeof CLEAR_CAMPAIGN_MESSAGES;
}

export type getCampaignDispatcher =
  | getCampaign
  | getCampaignError
  | deleteCampaignError 
  | clearCampaignMassages
  | addCampaign
  
