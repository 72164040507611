import React from "react";
import "./UpdateInformation.css";
import {useTranslation} from "react-i18next";
import ChecklistsStatus from "../SimpleComponents/ChecklistsStatus";
import ArrowIcon from "img/Dashboard/Checklists/arrow-right-solid.svg";
import moment from "moment";
import UserAvatar from "./UserAvatar";
import disabledIcon from "img/Dashboard/Checklists/disabled_update_icon.svg";
import activeIcon from "img/Dashboard/Checklists/active_update_icon.svg";
import calendarIcon from "img/Dashboard/Checklists/update_calendar_icon.svg";
import clockIcon from "img/Dashboard/Checklists/clock_calendar_item.svg";
import Button from "../Buttons/Button";

interface CustomProps {
  showChangeLog: (data:any) => void,
  data: any
}

export default function UpdateInformation({showChangeLog, data}:CustomProps) {
  const { t } = useTranslation();

  return(
    <div className="update_info_container">
      {data.length
        ?
        <>
          <div className="update_info_hint">
            {/*UPDATED BY*/}
            <div className="space-between">
              <p className="update_info_title">
                {t("dashboard.checklists.update_info.updated_by")}
              </p>
              <div className="row" style={{marginLeft: "15px", width: "100%"}}>
                <UserAvatar imageUrl={data[0].updatedBy.image} small/>
                <p className="update_info_text">
                  {data[0].updatedBy.name}
                </p>
              </div>
            </div>
            {/*UPDATED AT*/}
            <div className="space-between" style={{marginTop: "20px"}}>
              <p className="update_info_title">
                {t("dashboard.checklists.update_info.updated_at")}
              </p>
              <div className="row" style={{marginLeft: "15px", width: "100%"}}>
                <div className="row">
                  <img src={calendarIcon} width="25px" alt="calendar"/>
                  <p className="update_info_text">
                    {moment(data[0].updatedAt).format("DD.MM.YYYY")}
                  </p>
                </div>
                <div className="row" style={{marginLeft: "10px"}}>
                  <img src={clockIcon} width="25px" alt="clock"/>
                  <p className="update_info_text">
                    {moment(data[0].updatedAt).format("HH:mm")}
                  </p>
                </div>
              </div>
            </div>
            {/*STATUS*/}
            <div className="space-between" style={{marginTop: "20px"}}>
              <p className="update_info_title">
                {t("dashboard.checklists.update_info.status")}
              </p>
              <div className="row" style={{marginLeft: "15px", width: "100%"}}>
                {data[0].update && data[0].update.type === 0
                  ?
                  <div className="row">
                    <ChecklistsStatus type={+data[0].update.prevStatus} small />
                    <img src={ArrowIcon} alt="arrow" className="update_info_arrow"/>
                    <ChecklistsStatus type={+data[0].update.currStatus} small />
                  </div>
                  : null
                }
                {data[0].update && data[0].update.type === 1
                  ?
                  <div className="changelog_badge">
                    {t("dashboard.checklists.update_info.change_download")}
                  </div>
                  : null
                }
                {data[0].update && data[0].update.type === 2
                  ?
                  <div className="changelog_badge">
                    {t("dashboard.checklists.update_info.clicked_change")}
                  </div>
                  : null
                }
              </div>
            </div>
            <div style={{
              backgroundColor: "#A2ADBF",
              width: "100%",
              height: "1px",
              marginTop: "15px",
              marginBottom: "15px"
            }} />
            <div className="flex-end">
              <Button
                buttonLabel={t("dashboard.checklists.update_info.changelog_btn")}
                buttonType="outlined"
                buttonHandler={() => showChangeLog(data)}
              />
            </div>
          </div>
          <img src={activeIcon} className="update_info_icon" alt="info"/>
        </>
        : <img src={disabledIcon} className="update_info_icon" alt="info"/>
      }
    </div>
  );
}
