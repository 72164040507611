import React, {useState} from "react";
import { Button, message, Steps } from "antd";
import SelectRoleForAssociatedCompanies
  from "./AssociatedCompaniesStepper/SelectRoleForAssociatedCompanies";
import SelectCompanyForAssociatedCompanies
  from "./AssociatedCompaniesStepper/SelectCompanyForAssociatedCompanies";
import ChoseCompaniesForAssociatedCompanies
  from "./AssociatedCompaniesStepper/ChoseCompaniesForAssociatedCompanies";
import FinalResultForAssociatedCompanies
  from "./AssociatedCompaniesStepper/FinalResultForAssociatedCompanies";

const { Step } = Steps;

export default function AssociatedCompaniesSettings() {

  const [selectedCompany, setSelectedCompany] = useState<null | {id: string, title: string}>(null);
  const [selectedRole, setSelectedRole] = useState<null | {id: string, name: string}>(null);
  const [chosenCompanies, setChosenCompanies] = useState<string[]>([]);

  const steps = [
    {
      title: "Select company",
      content: <SelectCompanyForAssociatedCompanies
        onSelect={(company: {id: string, title: string}) => {
          setSelectedCompany(company);
          setCurrent(1);
        }}
      />,
    },
    {
      title: "Select admin user",
      content: <SelectRoleForAssociatedCompanies
        selectedCompany={selectedCompany}
        onSelect={(user: {id: string, name: string}) => {
          setSelectedRole(user);
          setCurrent(2);
        }}
      />,
    },
    {
      title: "Assign companies",
      content: <ChoseCompaniesForAssociatedCompanies
        selectedRole={selectedRole}
        onSelect={(data: any[]) => {
          setChosenCompanies(data);
          setCurrent(3);
        }}
      />,
    },
    {
      title: "Submit",
      content: <FinalResultForAssociatedCompanies
        selectedCompany={selectedCompany}
        selectedUser={selectedRole}
        chosenCompanies={chosenCompanies}
        onSubmit={() => {
          setSelectedRole(null);
          setSelectedCompany(null);
          setChosenCompanies([]);
          setCurrent(0);
        }}
      />,
    },
  ];

  const [current, setCurrent] = useState(0);

  return(
    <div style={{padding: "20px"}}>
      <h1>
        Manage Associated Companies for admin users
      </h1>
      <Steps current={current}>
        {steps.map(item => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div style={{marginTop: "20px"}}>{steps[current].content}</div>
    </div>
  );
}
