import React from "react";
import "./Button.css";

interface ButtonProps {
  buttonType: string;
  buttonLabel: string;
  buttonHandler?: () => void;
  submitButton?: boolean;
  buttonHtmlType?: "button" | "submit" | "reset";
  disabled?: boolean;
  fullWidth?: boolean;
  buttonStyles?: any;
}

const Button = ({
  buttonType,
  buttonLabel,
  buttonHandler,
  fullWidth,
  disabled,
  submitButton,
  buttonHtmlType,
  buttonStyles,
}: ButtonProps) => {
  const clickHandler = (e: any) => {
    e.preventDefault();
    buttonHandler && buttonHandler();
  };
  if (submitButton) {
    return (
      <button
        style={buttonStyles}
        disabled={disabled}
        type={buttonHtmlType}
        className={fullWidth ? buttonType + " btn_full" : buttonType + " btn"}
      >
        {buttonLabel}
      </button>
    );
  } else {
    return (
      <button
        data-testid="custom-element"
        disabled={disabled}
        style={buttonStyles}
        className={fullWidth ? buttonType + " btn_full" : buttonType + " btn"}
        onClick={(e) => clickHandler(e)}
      >
        {buttonLabel}
      </button>
    );
  }
};

export default Button;
