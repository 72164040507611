import "./Inputs.css";

import { motion } from "framer-motion";
import hidePasswordImg from "img/Dashboard/eye_closed.svg";
import showPasswordImg from "img/Dashboard/ic-actions-view.svg";
import React, { useState } from "react";

interface CustomInputProps {
  htmlFor: string;
  name: string;
  placeholder?: string;
  value: string;
  disabled: boolean;
  handleChange: any;
  onBlur?: any;
  label: string;
  style?: any;
  autoFocus?: boolean;
}

export default function FormikInput({
  name,
  placeholder,
  value,
  disabled,
  handleChange,
  htmlFor,
  onBlur,
  label,
  style,
  autoFocus,
}: CustomInputProps) {
  const [inputType, setInputType] = useState(false);

  if (htmlFor === "password") {
    return (
      <div style={style} className="simple_input">
        <label className="simple_label" htmlFor="password">
          {label}
        </label>
        <input
          autoFocus={autoFocus}
          name={name}
          type={!inputType ? "password" : "text"}
          className="formik_input"
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
        />
        <div className="hide-container">
          <img
            className="hide-toggle"
            src={inputType ? showPasswordImg : hidePasswordImg}
            alt="hide"
            onClick={() => setInputType(!inputType)}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div style={style} className="simple_input">
        <label className="simple_label" htmlFor={htmlFor}>
          {label}
        </label>
        <motion.input
          whileFocus={{ backgroundColor: "rgba(151, 151, 226, 0.281)" }}
          name={name}
          type="text"
          className={"simple_input"}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
        />
      </div>
    );
  }
}
