import "./ListStyles.css";

import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { getChecklists } from "../../redux/actions/checkListsActions";
import { RootState } from "../../redux/reducers/rootReducer";
import TableLoading from "../LoadingPlaceholders/TableLoading";
import ActionDotsChecklists from "../SimpleComponents/ActionsDotsChecklists";
import ChecklistsStatus from "../SimpleComponents/ChecklistsStatus";
import Pagination from "../SimpleComponents/Pagination";
import Spacer from "../SimpleComponents/Spacer";
import EmptyTableState from "../SimpleComponents/TableEmptyState";
import CustomToastContainer from "../SimpleComponents/ToastContainer";
import UpdateInformation from "../Containers/UpdateInformation";

interface CustomProps {
  checklists: any;
  selectedRows?: any;
  showLoading?: boolean;
  messages?: string;
  showChangeLog: (data:any) => void
}

function ChecklistsList({
  checklists,
  selectedRows,
  showLoading,
  messages,
  showChangeLog
}: CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const [pageLimit, setPageLimit] = useState(8);

  // Change limits of items in page
  const changePageLimit = (newLimit:number) => {
    if (newLimit !== pageLimit) {
      dispatch(getChecklists(newLimit, 0));
      setPageLimit(newLimit);
    }
  };

  // Initial clicked state
  useEffect(() => {
    if (checklists.data) {
      const newEmployee = checklists.data.map((item: any) => ({
        ...item,
        isClicked: false,
      }));

      if (selectedRows.length > 0) {
        newEmployee.forEach((selected: any) => {
          selectedRows.forEach((selectedInvoices: any) => {
            if (selected._id === selectedInvoices._id) {
              selected.isClicked = true;
            }
          });
        });
      }
      setLoading(false);
    }
    //Need to disable it, because eslint says, that I need to add
    //selectedRows as dependency but it will woke
    // that function in every render
  }, []);

  // Get success/error messages after update request
  useEffect(() => {
    if (messages?.length) {
      if (messages === "update_success") {
        dispatch(getChecklists(pageLimit, skip));
      } else if (messages === "update_error") {
        toast.error(
          <CustomToastContainer
            message={t("warnings.change_invoice_status")}
            status={3}
          />,
          {
            autoClose: 2000,
          }
        );
      }
    }
  }, [messages]);





  // const history = useHistory();
  // const location = useLocation();
  // console.log("search",location.search);

  // useEffect(()=>{
  //   switch(location.pathname){
  //   case "/checklists":
  //     return history.push("/checklists");
  //   }
  // },[]);


  const [skip, setSkip] = useState(0);

  // handle Paginate
  const currentPosts = checklists.data.slice(0, pageLimit);
  const paginate = (pageNumber: number) => {
    const skip = (pageNumber - 1) * pageLimit;
    setSkip(skip);
    dispatch(getChecklists(pageLimit, skip));
  };

  // Return name of checklist type
  const typeName = (type:number) => {
    switch (type) {
    case 0:
      return t("dashboard.checklists.type.0");
    case 1:
      return t("dashboard.checklists.type.1");
    case 2:
      return t("dashboard.checklists.type.2");
    case 3:
      return t("dashboard.checklists.type.3");
    case 4:
      return t("dashboard.checklists.type.4");
    }
  };

  return (
    <div style={{ marginTop: "2rem" }}>
      {showLoading ? (
        <TableLoading />
      ) : (
        <div className="table_box">
          {!checklists.data.length ? (
            <EmptyTableState style={{ maxHeight: "400px" }} />
          ) : (
            <div className="table_wrapper">
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th className="pl-10">
                      <div className="row">
                        <Spacer spacerWidth="1rem" />
                        {t("dashboard.checklists.list_th_type")}
                      </div>
                    </th>
                    <th>{t("dashboard.checklists.list_th_name")}</th>
                    <th>{t("dashboard.checklists.list_th_offer")}</th>
                    <th>{t("dashboard.checklists.list_th_status")}</th>
                    <th>
                      <p>{t("dashboard.checklists.list_th_dates")}</p>
                    </th>
                    <th>
                      <p>{t("dashboard.checklists.list_th_created")}</p>
                    </th>
                    <th>
                      <p>{t("dashboard.checklists.list_th_updated")}</p>
                    </th>
                    <th>
                      <p>{t("dashboard.checklists.list_th_action")}</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentPosts.map((checklist: any, index: number) => (
                    <React.Fragment key={checklist._id}>
                      <tr className="spacer" />
                      <tr className="table-item">
                        <td>
                          <div className="row">
                            <Spacer spacerWidth="1rem" />
                            {typeName(+checklist.type)}
                          </div>
                        </td>
                        <td>{checklist.type === 2 ? checklist.company : checklist.partner}</td>
                        <td>
                          {checklist.type === 2
                            ? t("dashboard.checklists.list_corporate_deal")
                            : checklist.offer
                          }
                        </td>
                        <td>
                          <ChecklistsStatus type={checklist.status} />
                        </td>
                        <td>
                          {+checklist.type === 4
                            ? <p>Kein Zeitraum</p>
                            : `${moment(checklist.startDate).format("DD.MM.YYYY")} - 
                            ${moment(checklist.endDate).format("DD.MM.YYYY")}`
                          }
                        </td>
                        <td>
                          {moment(checklist.createdAt).format("DD.MM.YYYY")}
                        </td>
                        <td>
                          <UpdateInformation
                            data={checklist.changelog}
                            showChangeLog={showChangeLog}
                          />
                        </td>
                        <td>
                          <ActionDotsChecklists
                            checklistsData={checklist}
                            skip={skip}
                          />
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
      <Pagination
        postsPerPage={pageLimit}
        totalPosts={checklists && checklists.totalChecklist}
        paginate={paginate}
        limit={pageLimit}
        changeLimits={(limit:number) => changePageLimit(limit)}
      />
    </div>
  );
}
const mapStateToProps = (state: RootState) => {
  return {
    selectedRows: state.selectedRows.selectedRows,
    showLoading: state.checklists.loading,
    messages: state.checklists.message,
  };
};

export default connect(mapStateToProps, null)(ChecklistsList);
