import searchImg from "img/search_icon.svg";
import React, { useState } from "react";

interface CustomProps {
  searchPlaceholder: string;
  employee?: any;
  sidebar?: any;
  handleSearchBar: (value: string) => void;
}

export default function SearchBar({
  searchPlaceholder,
  handleSearchBar,
}: CustomProps) {
  // handle searchbar for every page
  const [searchInputText, setSearchInputText] = useState("");

  const handleSearchInputChange = (e: any) => {
    e.preventDefault();
    handleSearchBar(searchInputText);
  };
  return (
    <form
      onSubmit={(e) => handleSearchInputChange(e)}
      className="search_container light_search"
    >
      <div onClick={handleSearchInputChange} className="search_button center">
        <img src={searchImg} className="search_icon" alt="search" />
      </div>
      <input
        type="text"
        placeholder={searchPlaceholder}
        onChange={(e) => setSearchInputText(e.target.value)}
      />
    </form>
  );
}
