export const DOWNLOAD_FILES_SUCCESS = "DOWNLOAD_FILES_SUCCESS";
export const DOWNLOAD_FILES_ERROR = "DOWNLOAD_FILES_ERROR";
export const CLEAR_FILES_MESSAGES = "DOWNLOAD_FILES_MESSAGES";

export interface clearDownloadMessages {
  type: typeof CLEAR_FILES_MESSAGES;
}

export interface postDownloadFileSuccess {
  type: typeof DOWNLOAD_FILES_SUCCESS;
  payload: any;
}
export interface postDownloadFileError {
  type: typeof DOWNLOAD_FILES_ERROR;
  payload: string;
}

export type postDownloadFileDispatchTypes =
  | postDownloadFileSuccess
  | postDownloadFileError
  | clearDownloadMessages;
