import "./HeaderComponentWithUser.css";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import HeaderLogo from "../../img/logo.svg";
import { logoutDispatcher } from "../../redux/actions/authActions";
import { prepareImage } from "../../redux/actions/prepareImage";
import { handleSidebar } from "../../redux/actions/sidebarActions";
import { RootState } from "../../redux/reducers/rootReducer";
import userArrow from "./icon-down.png";
import userAvatar from "./user_avatar.svg";
import {removeToken} from "../../utils/cookies/tokensCookie";
import {
  getUserRoles,
  removeUserPermission,
  removeUserRoles
} from "../../utils/cookies/userRolesCookies";
import {removeUserCompany, removeUserId} from "../../utils/cookies/userCookies";
import {useNavigate} from "react-router-dom";

interface CustomProps {
  withSidebar?: boolean;
  withSearch?: boolean;
  notifications?: any;
  handleSidebar: (sidebarOpen: boolean) => void;
  sidebar: boolean;
  firstName: string;
  lastName: string;
  userImg: string;
  userEmail: string;
}

function HeaderWithUser({
  withSidebar,
  handleSidebar,
  sidebar,
  firstName,
  lastName,
  userImg,
  userEmail,
}: CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userData = JSON.parse(localStorage.getItem("user") || "null");
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const openSearchBox = false;

  // taking user data
  const toggleMenu = () => {
    if (!menuOpen) {
      setMenuOpen(true);
    } else {
      setMenuOpen(false);
    }
  };

  // Detect click outside for close menu

  const headerMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event: any) => {
      if (!headerMenuRef.current?.contains(event.target as Node)) {
        setMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handleBurger = () => {
    handleSidebar(!sidebar);
  };
  const handleLogout = () => {
    dispatch(logoutDispatcher());
    sessionStorage.clear();
    removeToken();
    removeUserPermission();
    removeUserRoles();
    removeUserId();
    removeUserCompany();
    navigate("/login");
  };

  // Image sign handler
  const [currentImage, setCurrentImage] = useState("");
  useEffect(() => {
    async function fetchData() {
      if (userImg && userImg !== "undefined") {
        try {
          const response = await prepareImage(userImg);
          setCurrentImage(response);
        } catch (e) {
          console.error(e);
        }
      }
    }
    fetchData();
  }, [userImg]);

  const userType = getUserRoles();

  return (
    <header className="header">
      <div className="row">
        <div className="row" style={{ paddingLeft: "2vw" }}>
          <a href="/">
            <img
              className="header_logo"
              src={HeaderLogo}
              alt="Corplife-Logo"
              width="150px"
            />
          </a>
          {withSidebar && (
            <div className="burger_menu" onClick={handleBurger}>
              <div className={sidebar ? "burger_line" : "burger_line_close"} />
              <div className={sidebar ? "burger_line" : "burger_line_close"} />
              <div className={sidebar ? "burger_line" : "burger_line_close"} />
            </div>
          )}
          {/* {withSearch && (
						<SearchBarHeader
							searchPlaceholder="Search dashboard"
							searchClickHandlerMobile={searchClickHandlerMobile}
							openSearchBox={openSearchBox}
						/>
					)} */}
        </div>
      </div>
      {!openSearchBox && (
        <div className="user-container-header space-between">
          {/*<Link to="/admin/notification-center">
						<div className="header_notification">
							<img
								src={notificationImg}
								alt="notification"
							/>
							{notifications
							&& notifications.totalNotification > 0
							&& (
								<div className="notification_dot">
									{notifications.totalNotification}
								</div>
							)
							}
						</div>
					</Link>*/}
          <div
            ref={headerMenuRef}
            className="space-between"
            onClick={toggleMenu}
          >
            <div
              className="user-image-header"
              style={
                currentImage
                  ? { backgroundImage: `url(${currentImage})` }
                  : { backgroundImage: `url(${userAvatar})` }
              }
            ></div>
            <p className="user-name-header">
              {firstName ? `${firstName} ${lastName}` : userEmail}
            </p>
            <img
              src={userArrow}
              className={menuOpen ? "user-arrow-hide" : "user-arrow-show"}
              alt="arrow"
              width="20px"
            />
            <div className={menuOpen ? "user-menu-header" : "hide-menu"}>
              <div
                onClick={() => handleLogout()}
                className="header-user-menu-item row"
              >
                <p>
                  {t("components.header.menu_2")}
                  {userData ? userData.name : ""}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    sidebar: state.sidebar.sidebarCon,
    firstName: state.users.userInfo.firstName,
    lastName: state.users.userInfo.lastName,
    userImg: state.users.userInfo.imageUrl,
    userEmail: state.users.userInfo.email
  };
};

const mapDispatchToProps = {
  handleSidebar,
  logoutDispatcher,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderWithUser);
