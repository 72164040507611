import React, {useEffect, useState} from "react";
import axios from "axios";
import {getToken} from "../../../../utils/cookies/tokensCookie";
import environment from "../../../../environment";
import {urls} from "../../../../API/urls";
import { Select } from "antd";
import Button from "../../../../Components/Buttons/Button";

const { Option } = Select;

interface IProps {
  onSelect: (company: {id: string, title: string}) => void;
}

export default function SelectCompanyForAssociatedCompanies(props:IProps) {

  const [selectedCompany, setSelectedCompany] = useState<null | {id: string, title: string}>(null);

  const [data, setData] = useState([]);

  const getData = async () => {
    const token = getToken();
    const fullUrl = `${environment.baseUrl}${urls.lunch.companies.getAll}`;
    const options = {
      headers: {Authorization: `Bearer ${token}`},
      params: {
        limit: 999,
        skip: 0
      }
    };
    const request = await axios.get(fullUrl, options);
    setData(request.data.data);
  };

  // Get all companies
  useEffect(() => {
    getData();
  }, []);

  const handleChange = (value: string) => {
    const foundCompany:any = data.filter((company:any) => company._id === value);
    if (foundCompany) {
      setSelectedCompany({
        title: foundCompany[0].companyInfo.companyName,
        id: foundCompany[0]._id
      });
    }
  };

  return(
    <div>
      <h3>Select a company</h3>
      <Select defaultValue="Select company" style={{ width: 300 }} onChange={handleChange}>
        {data.map((company: any) => (
          <Option
            key={company._id}
            value={company._id}
          >
            {company.companyInfo.companyName}
          </Option>
        ))}
      </Select>
      <div style={{height: "20px"}} />
      <Button
        buttonType={"primary"}
        buttonLabel={"Submit"}
        disabled={!selectedCompany}
        buttonHandler={() => {
          if (selectedCompany) {
            props.onSelect(selectedCompany);
          }
        }}
      />
    </div>
  );
}
