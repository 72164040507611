const environment = {
  baseUrl: "https://api.corplife.at/v0/"
};

switch (process.env.REACT_APP_ENV) {
case "development":
  environment.baseUrl = "https://api-dev.corplife.at/v0/";
  break;
case "staging":
  environment.baseUrl = "https://api-stag.corplife.at/v0/";
  break;
case "production":
  environment.baseUrl = "https://api.corplife.at/v0/";
  break;
case "local":
  environment.baseUrl = "http://localhost:8000/v0/";
  break;
default:
  environment.baseUrl = "https://api.corplife.at/v0/";
  break;
}

export default environment;
