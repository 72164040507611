import "./DashboardPage.css";

import HeaderWithUser from "Components/Header/HeaderComponentWithUser";
import DashboardLoading from "Components/LoadingPlaceholders/DashboardLoading";
import React, {lazy, useEffect} from "react";
import { connect, useDispatch } from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import { changeSidebarTab } from "redux/actions/sidebarActions";

import Benefits from "../../Components/Pages/Benefits";
import ChecklistsPage from "../../Components/Pages/ChecklistsPages";
import CorporatesDetail from "../../Components/Pages/CorporatesDetail";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { getUserInfo } from "../../redux/actions/usersActions";
import { RootState } from "../../redux/reducers/rootReducer";
import { getToken } from "../../utils/cookies/tokensCookie";
import NotFoundPage from "../NotFoundPage";
import {getUserRoles} from "../../utils/cookies/userRolesCookies";
import EmployeePage from "../../Components/Pages/EmployeePage";
import CampaignList from "../../Components/Lists/CampaignList";
import CreateCampaign from "../../Components/Pages/CreateCampaign";
import PdfGenerationPage from "./PdfGenerationPage/PdfGenerationPage";
import CorplifeLunchRouter from "./Corplife Lunch/router";
const AnalyticsPage = lazy(() => import("../../Components/Pages/AnalyticsPage"));
interface CustomProps {
  sidebar?: boolean;
  sidebarTab?: string;
  handleSidebar?: (sidebarOpen: boolean) => void;
  expiredJWT?: boolean;
}

function DashboardPage({ sidebar, expiredJWT }: CustomProps) {
  // Taking location, rendering component and setting selected tab in Sidebar
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Protected route
  const token = getToken();
  useEffect(() => {
    if (!token || expiredJWT) {
      navigate(`/login?path=${window.location.pathname}`);
    }
  }, [token, history, expiredJWT, getUserRoles]);

  useEffect(() => {
    if (token) {
      dispatch(getUserInfo());
    }
  }, [dispatch, token]);

  const currentPage = location.pathname;
  const pageRendering = () => {
    // Corporates page
    if (location.pathname.toString().includes("benefits/")) {
      const path = window.location.pathname;
      const startIndex = window.location.pathname.lastIndexOf("/");
      const corporateId = path.slice(startIndex, path.length).trim();
      dispatch(changeSidebarTab("benefits"));
      return <CorporatesDetail corporateId={corporateId} />;
    }
    switch (currentPage) {
    case "/benefits":
      dispatch(changeSidebarTab("benefits"));
      return <Benefits />;
    case "/checklists":
      dispatch(changeSidebarTab("checklists"));
      return <ChecklistsPage />;
    case "/employee":
      dispatch(changeSidebarTab("employee"));
      return <EmployeePage />;
    case "/analytics":
      dispatch(changeSidebarTab("analytics"));
      return <AnalyticsPage />;
    case "/pdf":
      dispatch(changeSidebarTab("pdf"));
      return <PdfGenerationPage />;
    case "/lunch":
      dispatch(changeSidebarTab("lunch"));
      return <CorplifeLunchRouter />;
    default:
      return <NotFoundPage />;
    }
  };

  if (token) {
    return (
      <div className="dashboard_wrapper">
        <HeaderWithUser
          withSearch={false}
          withSidebar={true}
        />
        <div
          className={sidebar ? "dashboard_container" : "dashboard_hide_sidebar"}
        >
          <Sidebar showResponsiveSidebar={sidebar ? sidebar : false} />
          <div>{currentPage && pageRendering()}</div>
        </div>
      </div>
    );
  } else {
    return <DashboardLoading />;
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    sidebar: state.sidebar.sidebarCon,
    sidebarTab: state.sidebar.sidebarTabCondition
  };
};

export default connect(mapStateToProps, null)(DashboardPage);
