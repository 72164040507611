import customHistory from "../customHistory";
import {removeToken} from "./cookies/tokensCookie";
import {removeUserPermission, removeUserRoles} from "./cookies/userRolesCookies";
import {removeUserCompany, removeUserId} from "./cookies/userCookies";

export function errorHandler(status: number) {
  if (status === 404) {
    customHistory.push("/error");
    return true;
  } else if (status === 500) {
    customHistory.push("/server-error");
    return true;
  } else if (status === 401) {
    removeToken();
    removeUserPermission();
    removeUserRoles();
    removeUserId();
    removeUserCompany();
    customHistory.push("/login");
    return true;
  } else {
    return false;
  }
}
