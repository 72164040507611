import React, {useEffect, useState} from "react";
import "../SimpleComponents/Modal.css";
import Button from "../Buttons/Button";
import {useFormik} from "formik";
import * as Yup from "yup";
import {connect, useDispatch} from "react-redux";
import closeIcon from "../../img/Dashboard/Employee/close_btn.svg";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";
import FormikInput from "../SimpleComponents/FormikInput";
import {addOneEmployee, updateEmployee} from "../../redux/actions/usersActions";

interface CustomProps {
  closeModal: () => void,
  selectedUserData?: any
}

export default function EmployeeModal({closeModal, selectedUserData}:CustomProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const formik = useFormik<any>({
    initialValues: {
      imageUrl: "undefined",
      firstName: "",
      lastName: "",
      email: ""
    },
    onSubmit: values => {
      values.firstName = values.firstName.substr(0, 1).toUpperCase()
        + values.firstName.substr(1);
      values.lastName = values.lastName.substr(0, 1).toUpperCase()
        + values.lastName.substr(1);
      Object.values(selectedUserData).length
        ? dispatch(updateEmployee(values, selectedUserData._id))
        : dispatch(addOneEmployee(values));
      closeModal();
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required(t("formik_warnings.general_info.firstName")),
      lastName: Yup.string().required(t("formik_warnings.general_info.lastName")),
      email: Yup.string().required(t("formik_warnings.general_info.email"))
        .email(t("formik_warnings.general_info.emailValid"))
    }),
  });

  useEffect(() => {
    if (Object.values(selectedUserData).length) {
      formik.setFieldValue("firstName", selectedUserData.firstName, true);
      formik.setFieldValue("lastName", selectedUserData.lastName, true);
      formik.setFieldValue("email", selectedUserData.email, true);
    }
  }, [selectedUserData]);

  const [buttonLoading, setButtonLoading] = useState(false);

  return(
    <div className="modal_container">
      <img
        onClick={() => closeModal()}
        className="close_popup"
        src={closeIcon} alt="close"/>
      <h2>
        {Object.values(selectedUserData).length
          ? t("dashboard.employee.modal.update_title", {
            name: `${selectedUserData.firstName} ${selectedUserData.lastName}`
          })
          : t("dashboard.employee.modal.add_title")
        }
      </h2>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="relative"  style={{width:"90%"}}>
            <FormikInput
              style={{width:"100%", marginRight:"15px"}}
              htmlFor="firstName"
              name="firstName"
              value={formik.values.firstName}
              disabled={false}
              handleChange={formik.handleChange}
              label={t("dashboard.employee.modal.label_firstName")}
              onBlur={formik.handleBlur}
            />
            {formik.errors.firstName
            && formik.touched.firstName
            && <p
              className="input_warning"
            >
              {formik.errors.firstName.toString()}
            </p>}
          </div>
          <div className="relative"  style={{width:"90%",marginLeft:"20px"}}>
            <FormikInput
              style={{width:"100%"}}
              htmlFor="lastName"
              name="lastName"
              value={formik.values.lastName}
              disabled={false}
              handleChange={formik.handleChange}
              label={t("dashboard.employee.modal.label_lastName")}
              onBlur={formik.handleBlur}
            />
            {formik.errors.lastName
            && formik.touched.lastName
            && <p
              className="input_warning"

            >
              {formik.errors.lastName.toString()}
            </p>}
          </div>
        </div>
        <div className="relative">
          <FormikInput
            htmlFor="email"
            name="email"
            value={formik.values.email}
            disabled={false}
            handleChange={formik.handleChange}
            label={t("dashboard.employee.modal.label_email")}
            onBlur={formik.handleBlur}
          />
          {formik.errors.email
          && formik.touched.email
          && <p
            className="input_warning"
          >
            {formik.errors.email.toString()}
          </p>}
        </div>
        {buttonLoading
          ?
          <div className="center primary btn_full">
            <BeatLoader size="10px" color="white" />
          </div>
          :
          <Button
            disabled={!(formik.isValid && formik.dirty)}
            buttonType="primary"
            buttonHtmlType="submit"
            buttonLabel={Object.values(selectedUserData).length
              ? t("dashboard.employee.modal.btn_update")
              : t("dashboard.employee.modal.btn_add")
            }
            submitButton={true}
            fullWidth={true}
          />
        }
      </form>
    </div>
  );
}
