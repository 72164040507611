import "./Sidebar.css";

import React from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { changeSidebarTab } from "redux/actions/sidebarActions";
import { RootState } from "redux/reducers/rootReducer";

import { changeSelectedRows } from "../../redux/actions/selectedUsersFromListActions";
import SidebarItem from "./SidebarItem";
import {useNavigate} from "react-router-dom";

interface CustomProps {
  showResponsiveSidebar: boolean;
  sidebarTab: string;
  changeSidebarTab: (sidebarTabCondition: string) => void;
}

function Sidebar({
  showResponsiveSidebar,
  sidebarTab,
  changeSidebarTab,
}: CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // set tab style on click and change a path
  const handleSidebarChanges = (page: string) => {
    // Clear checked rows state after changing page
    dispatch(changeSelectedRows([]));
    changeSidebarTab(page);
    page !== "dashboard"
      ? navigate(`/${page}`)
      : navigate("/");
  };

  return (
    <div
      className={showResponsiveSidebar ? "sidebar_container" : "hide_sidebar"}
    >
      <ul>
        <SidebarItem
          openSidebar={showResponsiveSidebar}
          selectedTab={sidebarTab}
          handleSidebarChanges={handleSidebarChanges}
          itemName="benefits"
          itemText={t("components.sidebar.subMenu.11")}
          subItem
        />
        <SidebarItem
          openSidebar={showResponsiveSidebar}
          selectedTab={sidebarTab}
          handleSidebarChanges={handleSidebarChanges}
          itemName="checklists"
          itemText={t("components.sidebar.subMenu.12")}
          subItem
        />
        {/*<SidebarItem
          openSidebar={showResponsiveSidebar}
          selectedTab={sidebarTab}
          handleSidebarChanges={handleSidebarChanges}
          itemName="campaigns"
          itemText={t("components.sidebar.subMenu.13")}
          subItem
        />*/}
        <SidebarItem
          openSidebar={showResponsiveSidebar}
          selectedTab={sidebarTab}
          handleSidebarChanges={handleSidebarChanges}
          itemName="employee"
          itemText={t("components.sidebar.subMenu.3")}
          subItem
        />
        <SidebarItem
          openSidebar={showResponsiveSidebar}
          selectedTab={sidebarTab}
          handleSidebarChanges={handleSidebarChanges}
          itemName="analytics"
          itemText={t("components.sidebar.subMenu.11")}
          subItem
        />
        <SidebarItem
          openSidebar={showResponsiveSidebar}
          selectedTab={sidebarTab}
          handleSidebarChanges={handleSidebarChanges}
          itemName="pdf"
          itemText={t("components.sidebar.subMenu.14")}
          subItem
        />
        <SidebarItem
          openSidebar={showResponsiveSidebar}
          selectedTab={sidebarTab}
          handleSidebarChanges={handleSidebarChanges}
          itemName="lunch"
          itemText={t("components.sidebar.subMenu.15")}
          subItem
        />

        <a
          className="footer_link"
          rel="noreferrer"
          href="https://www.corplife.at/agbs/unternehmenspartner-lunch"
          target="_blank"
        >
          {t("dashboard.home.agb")}
        </a>
      </ul>
    </div>
  );
}
const mapStateToProps = (state: RootState) => {
  return {
    sidebar: state.sidebar.sidebarCon,
    sidebarTab: state.sidebar.sidebarTabCondition,
  };
};
const mapDispatchToProps = {
  changeSidebarTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
