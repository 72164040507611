import axios from "axios";
import { Dispatch } from "redux";

import environment from "../../environment";
import { errorHandler } from "../../utils/errorHandler";
import { encryptWithAES, roleKey } from "../../utils/permissionsAllow";
import { removeToken, setToken } from "../../utils/cookies/tokensCookie";
import {
  AUTH_ERROR,
  authDispatchTypes,
  CHANGE_PASSWORD,
  CLEAR_AUTH_MESSAGES,
  LOGIN_SUCCESS,
  LOGOUT,
  RESET_PASSWORD,
  USER_TOKEN,
} from "../types/authTypes";
import {setUserPermission, setUserRoles} from "../../utils/cookies/userRolesCookies";
import {setUserCompany, setUserId} from "../../utils/cookies/userCookies";
import {urls} from "../../API/urls";

// Login
export const loginUser =
  (email: string, password: string) =>
    async (dispatch: Dispatch<authDispatchTypes>) => {
      try {
        const fullUrl = `${environment.baseUrl}${urls.auth.login}`;
        await axios
          .post(fullUrl, {
            email,
            password,
            deviceName: "mobile_ios",
          })
          .then(function (response) {
            setToken(response.data.token.token);
            const id = response.data.token.userId;
            setUserId(id);
          });
        dispatch({
          type: LOGIN_SUCCESS,
        });
      } catch (e: any) {
        dispatch({
          type: AUTH_ERROR,
          payload: "login",
        });
      }
    };

// Change password
export const changePassword =
  (data: any) => async (dispatch: Dispatch<authDispatchTypes>) => {
    const fullUrl = `${environment.baseUrl}${urls.auth.resetPassword}`;
    try {
      await axios.post(fullUrl, data);
      dispatch({
        type: CHANGE_PASSWORD,
        payload: "change_password",
      });
    } catch (e: any) {
      if (!errorHandler(e.request.status)) {
        dispatch({
          type: AUTH_ERROR,
          payload: "change_password",
        });
      }
    }
  };

// Change password from email
export const changePasswordFromEmail =
  (data: any, code: string) =>
    async (dispatch: Dispatch<authDispatchTypes>) => {
      try {
        const response = await axios.post(`${environment.baseUrl}${urls.auth.codeValidate}`, {
          code: code,
          email: data.email,
        });
        if (response.data.validated) {
          const fullUrl = `${environment.baseUrl}${urls.auth.resetPassword}`;
          try {
            await axios.post(fullUrl, data);
            dispatch({
              type: CHANGE_PASSWORD,
              payload: "change_password",
            });
          } catch (e: any) {
            if (!errorHandler(e.request.status)) {
              dispatch({
                type: AUTH_ERROR,
                payload: "change_password",
              });
            }
          }
        } else {
          dispatch({
            type: AUTH_ERROR,
            payload: "wrong_verification",
          });
        }
      } catch (e: any) {
        console.log(e);
        if (!errorHandler(e.request.status)) {
          dispatch({
            type: AUTH_ERROR,
            payload: "wrong_verification",
          });
        }
      }
    };

// Reset password
export const resetPassword =
  (data: any) => async (dispatch: Dispatch<authDispatchTypes>) => {
    const fullUrl = `${environment.baseUrl}${urls.auth.resetEmail}`;
    try {
      await axios.post(fullUrl, data);
      dispatch({
        type: RESET_PASSWORD,
        payload: "reset_password",
      });
    } catch (e: any) {
      if (!errorHandler(e.request.status)) {
        dispatch({
          type: AUTH_ERROR,
          payload: "reset_password",
        });
      }
    }
  };

// Get user token
export const userTokenDispatcher = (token: string) => (dispatch: Dispatch) => {
  dispatch({
    type: USER_TOKEN,
    payload: token,
  });
};

// Logout user
export const logoutDispatcher = () => (dispatch: Dispatch) => {
  removeToken();
  dispatch({
    type: LOGOUT,
  });
};

// Clear messages
export function clearAuthMessages() {
  return {
    type: CLEAR_AUTH_MESSAGES,
  };
}
