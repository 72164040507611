export const GET_USERS = "GET_USERS";
export const GET_USER_INFO = "GET_USER_INFO";
export const GET_ONE_USER = "GET_ONE_USER";
export const USER_STATISTIC = "USER_STATISTIC";
export const INACTIVE_USERS = "INACTIVE_USERS";
export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS";
export const ADD_MANY_EMPLOYEE = "ADD_MANY_EMPLOYEE";
export const ADD_ONE_EMPLOYEE = "ADD_ONE_EMPLOYEE";
export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const DELETE_ONE_USER = "DELETE_ONE_USER";
export const DELETE_MANY_USERS = "DELETE_MANY_USERS";
export const USERS_ERROR = "USERS_ERROR";
export const CLEAR_USERS_MESSAGES = "CLEAR_USERS_MESSAGES";
export const SHOW_USER_LOADING = "SHOW_USER_LOADING";

interface getUsersAction {
  type: typeof GET_USERS;
  payload: any;
}

interface getUserInfoAction {
  type: typeof GET_USER_INFO;
  payload: any;
}

interface getOneUserAction {
  type: typeof GET_ONE_USER;
  payload: any;
}

interface userStatisticAction {
  type: typeof USER_STATISTIC;
  payload: any;
}

interface inactiveUsersAction {
  type: typeof INACTIVE_USERS;
  payload: any;
}

interface inviteUserAction {
  type: typeof INVITE_USER_SUCCESS;
  payload: any;
}

interface addManyEmployeeAction {
  type: typeof ADD_MANY_EMPLOYEE;
  payload: any;
}

interface addOneEmployeeAction {
  type: typeof ADD_ONE_EMPLOYEE;
  payload: any;
}

interface updateEmployeeAction {
  type: typeof UPDATE_EMPLOYEE;
  payload: any;
}

interface deleteOneUserAction {
  type: typeof DELETE_ONE_USER;
  payload: any;
}

interface deleteManyUsersAction {
  type: typeof DELETE_MANY_USERS;
  payload: any;
}

interface usersErrorAction {
  type: typeof USERS_ERROR;
  payload: string;
}

interface showUserLoading {
  type: typeof SHOW_USER_LOADING;
}

interface clearUsersMessages {
  type: typeof CLEAR_USERS_MESSAGES;
}

export type usersTypes =
  | getUsersAction
  | getUserInfoAction
  | usersErrorAction
  | userStatisticAction
  | inactiveUsersAction
  | inviteUserAction
  | addManyEmployeeAction
  | addOneEmployeeAction
  | updateEmployeeAction
  | deleteOneUserAction
  | clearUsersMessages
  | deleteManyUsersAction
  | showUserLoading
  | getOneUserAction;
