import "../LoginPage/LoginPage.css";

import { useFormik } from "formik";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import { toast } from "react-toastify";
import * as Yup from "yup";

import Button from "../../Components/Buttons/Button";
import ForgotPassword from "../../Components/Containers/ForgotPassword";
import FormikInput from "../../Components/SimpleComponents/FormikInput";
import CustomToastContainer from "../../Components/SimpleComponents/ToastContainer";
import HeaderLogo from "../../img/logo.svg";
import { clearAuthMessages, loginUser } from "../../redux/actions/authActions";
import { getUserInfo } from "../../redux/actions/usersActions";
import { RootState } from "../../redux/reducers/rootReducer";
import { getToken } from "../../utils/cookies/tokensCookie";
import {useNavigate} from "react-router-dom";

interface CustomProps {
  login?: any;
  user?: any;
}

function LoginPage({ login, user }: CustomProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("login.email_error"))
        .required(t("login.required")),
      password: Yup.string().min(4, t("login.password_error")),
    }),
    // handle form submitting
    onSubmit: () => console.log("CLICK"),
  });

  // loading after login
  const [showLoading, setShowLoading] = useState(false);

  // State for showing forgot password component
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  // Protected Routes !showWelcome need to show image after login
  const token = getToken();

  useEffect(() => {
    if (token) {
      dispatch(getUserInfo());
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (Object.keys(user).length && token) {
      if (token) {
        if (
          window.location.search.slice(6, window.location.search.length).trim()
            .length
        ) {
          navigate(
            window.location.search.slice(6, window.location.search.length)
          );
        } else {
          navigate("/");
        }
      }
    }
    //Need to disable it, because eslint says, that I need to add
    //more deps, but I need to work it only user changes
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (login.error === "login") {
      dispatch(clearAuthMessages());
      toast.error(
        <CustomToastContainer
          message={t("login.server_declined")}
          status={3}
        />,
        {
          autoClose: 2000,
        }
      );
      setShowLoading(false);
    }
  }, [login, t]);

  /* Handle login form */
  const handleLoginSubmit = (e: any) => {
    e.preventDefault();
    if (
      formik.errors.email ||
      formik.errors.password ||
      !formik.values.email ||
      !formik.values.password
    ) {
      toast.warning(t("login.email_error"));
    } else {
      setShowLoading(true);
      dispatch(
        loginUser(formik.values.email.toLowerCase(), formik.values.password)
      );
    }
  };

  return (
    <div className="login-main-container center">
      <CookieConsent
        overlay={true}
        location="bottom"
        containerClasses="cookie_container"
        cookieName="myAwesomeCookieName2"
        expires={150}
        buttonText={t("cookies.button")}
        buttonClasses="cookie_button"
      >
        <span className="cookie_title">{t("cookies.title")}</span>
        <p className="cookie_text">
          {t("cookies.text_1")}
          <span>
            <a
              rel="noreferrer"
              target="_blank"
              className="cookie_link"
              href="https://www.corplife.at/agbs/unternehmenspartner-lunch"
            >
              {t("cookies.link_1")}
            </a>
          </span>
          <span>{t("cookies.text_2")}</span>
        </p>
      </CookieConsent>
      <img src={HeaderLogo} className="login_logo" alt="logo" />
      <div className="login_block">
        <h1>{t("login.welcome_title")}</h1>
        <h2>{t("login.welcome_text")}</h2>
        <div className="login-container">
          {showForgotPassword ? (
            <ForgotPassword backToLogin={() => setShowForgotPassword(false)} />
          ) : (
            <>
              <h3 className="login-form-title">{t("login.title")}</h3>
              <form
                onSubmit={(e) => handleLoginSubmit(e)}
                className="login-form"
              >
                <div style={{ position: "relative" }}>
                  <FormikInput
                    htmlFor="email"
                    name="email"
                    value={formik.values.email}
                    disabled={false}
                    handleChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label={t("login.email_placeholder")}
                    placeholder={t("login.forgot_placeholder")}
                  />
                  {formik.errors.email && formik.touched.email && (
                    <motion.p
                      animate={{ y: 5 }}
                      // transition={{duration:0.50}}
                      className="input_error"
                      style={{ position: "absolute", fontSize: "10px" }}
                    >
                      {formik.errors.email}
                    </motion.p>
                  )}
                </div>
                <div style={{ position: "relative" }}>
                  <FormikInput
                    htmlFor="password"
                    name="password"
                    value={formik.values.password}
                    disabled={false}
                    handleChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label={t("login.password_label")}
                    placeholder={t("login.password_placeholder")}
                  />
                  {formik.errors.password && formik.touched.password && (
                    <motion.p
                      animate={{ y: 5 }}
                      // transition={{duration:0.50}}
                      className="input_error"
                      style={{ position: "absolute", fontSize: "10px" }}
                    >
                      {formik.errors.password}
                    </motion.p>
                  )}
                </div>
                {showLoading ? (
                  <div className="center login_btn btn_full">
                    <BeatLoader size="10px" color="white" />
                  </div>
                ) : (
                  <Button
                    buttonStyles={{ marginTop: "20px" }}
                    buttonType="login_btn"
                    buttonHtmlType="submit"
                    buttonLabel={t("login.button")}
                    submitButton={true}
                    fullWidth={true}
                  />
                )}
                <p
                  onClick={() => setShowForgotPassword(true)}
                  className="remember-text"
                >
                  {t("login.forgot_password")}
                </p>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    login: state.auth,
    token: state.auth.userToken,
    user: state.users.userInfo,
  };
};
const mapDispatchToProps = {
  loginUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
